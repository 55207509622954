import React, { useEffect, useState } from 'react';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import ModifierItem from './ModifierItem';
import { formatCurrency } from '../Payment';
import Icon, { IconType } from '../Icon';
import { IObject } from '../bm-common';
import { IReducersState } from '@/reducers';
import { getI18nText } from '@/utils/app';
import InputItem from 'antd-mobile/es/input-item';

interface IListingsItemProps {
  data: LineItemEntity;
  showRightTopCount: boolean;
  className?: string;
  style?: IObject;
  onPlusClick?: (data: LineItemEntity) => void;
  onMinusClick?: (data: LineItemEntity) => void;
  reducersState: IReducersState;
  handleChangeQuantityTo?: (data: LineItemEntity, quantity: number) => void;
}

const ListingsItem: React.FC<IListingsItemProps> = props => {
  const {
    data,
    className = '',
    showRightTopCount = false,
    style = {},
    onPlusClick,
    onMinusClick,
    reducersState,
    handleChangeQuantityTo,
  } = props;

  const iconUrl = data.imageUrl;

  let initQuantity = 0;

  if (data.quantity > 0) {
    initQuantity = data.quantity;
  }

  const [quantity, setQuantity] = useState<string>('0');

  useEffect(() => {
    setQuantity(initQuantity.toString());
  }, [initQuantity]);

  const handleMinusClick = () => {
    if (onMinusClick) {
      onMinusClick(data);
    }
  }

  const handlePlusClick = () => {
    if (onPlusClick) {
      onPlusClick(data);
    }
  }

  const handleCloseKeyboard = () => {
    let changeQuantity = parseInt(quantity);
    if (handleChangeQuantityTo) {
      if (isNaN(changeQuantity)) {
        changeQuantity = 0;
      }
      handleChangeQuantityTo(data, changeQuantity);
    }
  }

  return (
    <div className={`listings-item div-block-482 ${className}`} style={style}>
      <div className="div-block-481 checkout-process" style={{backgroundImage: `url(${iconUrl || '/images/default-store.png'})`}}></div>
      <div>
        <div className="item-info-wrapper cart-process">
          <div className="item-info checkout-process">
            <h4 className="heading-4-1 checkout-process">{getI18nText(data, 'name', reducersState.app.language) || data.name}</h4>
            {
              showRightTopCount &&
              <div className="heading-4-1 checkout-process">{`X ${data.quantity}`}</div>
            }
            {/* <p className="paragraph-2"></p> */}
          </div>
          <div className="describe">
            {
              data.modifierEntries && data.modifierEntries.map(item => (
                <ModifierItem
                  key={item.uuid}
                  data={item}
                  reducersState={reducersState}
                />
              ))
            }
          </div>
          <div className="price-cart">
            <div className="text-block-6">
              {formatCurrency(data.subtotal, data.currencyCode)}
            </div>
            {
              (onPlusClick || onMinusClick || handleChangeQuantityTo) &&
              <div className="q-flex-cart">
                {
                  data.quantity > 0 &&
                  <>
                    <Icon
                      className="q-dec-cart checkout-process item-minus"
                      type={IconType.MINUS_CIRCLE_OUTLINED}
                      onClick={handleMinusClick}
                    />
                    <div className="count">
                      <InputItem
                        type="bankCard"
                        maxLength={4}
                        // disabled={disable}
                        value={quantity}
                        onBlur={handleCloseKeyboard}
                        onChange={setQuantity}
                      />
                      {/* {data.quantity} */}
                    </div>
                  </>
                }
                <Icon
                  className="item-plus checkout-process q-inc-cart"
                  type={IconType.PLUS_CIRCLE_OUTLINED}
                  onClick={handlePlusClick}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingsItem;
