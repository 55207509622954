
import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import InputItem from 'antd-mobile/es/input-item';
import Modal from 'antd-mobile/es/modal';
import Button from 'antd-mobile/es/button';
import AntdIcon from 'antd-mobile/es/icon';
import Layout from '@/components/Layout/Layout';
import ActionType from '@/actions/action-type';
import PayInfo from '@/components/PayInfo';
import { IPayCard, IGiftCard } from '@/actions/pay-action';
import { giftCardToPayMethod, creditCardToPayMethod } from '@/utils/pay';
import { IOrderInfo, ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { parseRouteParams, findNode, minus, createRouteUrl, getDomain, sameItemInMultipleArray, getPlatformPayInfo } from '@/utils';
import { setScreenColor, getAppTheme, getIntl } from '@/components/App/App';
import { IObject } from '@/components/bm-common';
import { usePayment, PayTypes, IPayMethod, useCheckPay as UseCheckPay} from '@/lib/payment';
import paths from '@/routes/paths';
import { IStore, IStoreConfig } from '@/actions/store-action';
import PayBillMethodModal from '@/components/PayBillMethodModal';
import PayCardModal from '@/components/PayCardModal';
import { emailSignUpV2 } from '@/data/sign';
import { createUuid, plus } from '@/lib/order-builder/utils'
import Constants from '@/constants';
import { prefix } from '.';
import Icon, { IconType } from '@/components/Icon';
import { getI18nText, setAccessToken, getAccessToken } from '@/utils/app';
import Skeleton from './Skeleton';
import { formatCurrencyCode } from '@/components/Payment';
import { PayBillPageStyled } from './styled';
import Config from '@/Config';


export interface IPayBillPageProps extends IBasePageProps { }
interface IPayBillState {
  amount: string;
  showModal: boolean;
  store: IStore | undefined;
  selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
  selectedMethod: string | undefined; // 当前选中的
  paying: boolean;
  showPayCardModal: boolean;
  checking: boolean;
  loadingOrerInfo: boolean;
  initLoading: boolean;
  orderInfo?: IOrderInfo;
  storeConfig: IStoreConfig | undefined;
  payBill: boolean;
  isFromPage?: boolean;
  realDomain?: string;
}

class PayBillPage extends BasePage<IPayBillPageProps, IPayBillState> {
  routeParams: IObject = {};

  state: IPayBillState = {
    amount: '',
    showModal: false,
    selectedGiftCard: undefined,
    selectedMethod: undefined,
    orderInfo: undefined,
    store: undefined,
    paying: false,
    showPayCardModal: false,
    checking: false,
    loadingOrerInfo: false,
    initLoading: true,
    storeConfig: undefined,
    payBill: false,
    isFromPage: false,
    realDomain: '',
  }

  async componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }
    super.componentDidMount();

    let initLoading = false;

    this.initChenckData();

    const { payInfo } = this.props.routeParams;
    if (typeof payInfo === 'string' && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payBillInfo = getPlatformPayInfo(payInfo);
      if (payBillInfo.token) {
        setAccessToken(payBillInfo.token);
      }
    }

    if (!getAccessToken()) {
      initLoading = true;
      await this.initUser();
    } else {
      this.queryStore();
    }
    this.initPayMethod();

    
    this.setState({
      initLoading,
    })
  }

  queryStore = () => {
    const { routeParams, dispatch } = this.props;
    const { storeSlug } = routeParams;
    dispatch({
      type: ActionType.QUERY_STORE,
      storeSlug,
      currentPlatformID: routeParams.platformID || '',
      callback: (store, storeConfig) => {
        if (storeConfig) {
          this.setState({
            store,
            storeConfig,
            payBill: storeConfig.payBill,
          })
          dispatch({
            type: ActionType.LOADING,
            showLoading: false,
          })
        }
      }
    })
  }

  initPayMethod = () => {
    const { reducersState } = this.props;
    this.routeParams = parseRouteParams(this.props);
    const { payMethods, anonymousGiftCards, keyRefGiftCards } = reducersState.pay;

    let selectedGiftCard: IGiftCard | undefined; // 当前选中的购物卡
    let selectedMethod: string | undefined; // 当前选中的
    const storeGiftCards: IGiftCard[] = [];
    Object.values(keyRefGiftCards).forEach(items => {
      storeGiftCards.push(...items);
    });
    payMethods.forEach(item => {
      if ([PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(item.type)) {
        let gcItem = findNode<IGiftCard>(anonymousGiftCards, 'id', item.id);
        if (!gcItem) {
          gcItem = findNode<IGiftCard>(storeGiftCards, 'id', item.id);
        }
        if (gcItem) {
          selectedGiftCard = gcItem;
        }
      } else if (item.type === PayTypes.CREDIT_CARD) {
        selectedMethod = `${item.creditCardType}_${item.id}`;
      } else {
        selectedMethod = item.type;
      }
    });

    this.setState({
      selectedMethod,
      selectedGiftCard,
    });
  }

  initChenckData = () => {
    const { reducersState, dispatch } = this.props;

    this.routeParams = parseRouteParams(this.props);
    const { fromUrlPayData } = reducersState.pay;

    if (this.routeParams.check === '1') {
      this.setState({ paying: true, checking: true });
      if (this.routeParams.amount) {
        this.setState({ amount: this.routeParams.amount })
      }
    } else {
      this.setState({ checking: false });
    }

    if (this.routeParams.orderNumber && this.routeParams.storeSlug) {
      dispatch({
        type: ActionType.QUERY_ORDER_INFO,
        storeSlug: this.routeParams.storeSlug,
        orderNumber: this.routeParams.orderNumber,
        fromUrlPayData,
        callback: (orderInfo, store, storeConfig) => {
          this.setState({ orderInfo, store, loadingOrerInfo: false });
          if (this.routeParams.check === '1' && store) {
            this.checkPayResult(store.id, this.routeParams.orderNumber);
          }
        }
      });
    } else {
      this.setState({ loadingOrerInfo: false });
    }
  }

  initUser = async () => {
    const { dispatch, reducersState, routeParams } = this.props;
    const signUpEmail = `${createUuid()}_HR@bindo.com`;

    const { platformAppSettings } = reducersState.app;
    
    const platformID = routeParams.platformID ? routeParams.platformID : platformAppSettings ? parseInt(platformAppSettings.id) : 0;

    dispatch({
      type: ActionType.LOADING,
      showLoading: true,
    });
    try {
      const resp = await emailSignUpV2({
        userEmail: signUpEmail,
        password: signUpEmail,
        fullName: 'PAY_BILL_USER',
        platformID,
        registerType: 1,
      });

      // 注册完成后不能马上注册, 因为服务器可能会401
      if (resp && resp.success) {
        setTimeout(() => {
          dispatch({
            type: ActionType.SIGN_IN,
            username: signUpEmail,
            password: signUpEmail,
            loginMethod: 'email',
            callback: (success, userInfo) => {
              dispatch({
                type: ActionType.LOADING,
                showLoading: false,
              });
              this.queryStore();
              this.setState({ initLoading: false });
            }
          });
        }, 1000);
      }
    } catch (error) { }
  }
  parseFormat = (value: string) => {
    const integerPart = `${value}`.split('.')[0];
    const decimalPart = `${value}`.split('.')[1];
    return integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',') + `${decimalPart || value.includes('.') ? `.${decimalPart}` : ''}`
  }
  handleAmountChange = (values: string) => {
    const { dispatch, reducersState } = this.props;
    const { payMethods } = reducersState.pay;
    const newPayMethods: IPayMethod[] = JSON.parse(JSON.stringify(payMethods));
    let value = values;
    if (values.indexOf('.') !== -1) {
      const a = values.substr(0, values.indexOf('.')+3);
      value = a.replace(/[^0-9.]/g, '');
    } else {
      value = values.replace(/[^0-9.]/g, '');
    }
    
    const { selectedGiftCard } = this.state;
    let balance = 0;
    if (selectedGiftCard && selectedGiftCard.balance < Number(value)) {
      balance = selectedGiftCard.balance || 0;
    }
    
    this.setState({
      amount: value,
    });
    if (newPayMethods.length > 0) {

    }
    newPayMethods.forEach(item => {
      if (item.type === PayTypes.ANONYMOUS_GIFT_CARD || item.type === PayTypes.STORE_CREDIT) {
        item.amount = minus(balance, Number(value)) > 0 ? Number(value) : balance;
      } else {
        item.amount = minus(Number(value), balance);
      }
    });
    dispatch({
      type: ActionType.MODIFY_PAY_METHODS,
      payMethods: [...newPayMethods],
    });
  }

  handlePrecentile = () => {
    const { amount } = this.state;
    if (!amount) {
      return;
    }
    this.setState({
      amount: amount,
    })
  }

  handlePayMethod = () => {
    this.setState({ showModal: true });
  }

  handleCloseClick = () => {
    this.setState({ showModal: false });
  }

  checkPayResult = async (storeID: string, orderNumber: string) => {
    await UseCheckPay({
      storeID,
      orderNumber,
      callback: this.checkPayCallback,
    });
  }

  checkPayCallback = (data: any) => {
    const { order } = data || {};

    if (order && order.state === 'completed') {
      this.gotoPayResultsPage(order);
    }

    this.setState({ paying: false });
  }

  handleItemClick = (method: PayTypes, data?: IGiftCard | IPayCard) => {
    let { selectedGiftCard } = this.state;
    const { amount, store } = this.state;
    const { dispatch, reducersState } = this.props;


    const amountNum = parseFloat(amount);

    const orderInfo = {
      currencyCode: store ? store.currencyCode : 'HKD',
      initialTotal: amountNum,
    }

    let giftCardPayMethods: IPayMethod[] = [];
    let balance = 0;
    if (selectedGiftCard && selectedGiftCard.balance < amountNum) {
      balance = selectedGiftCard.balance || 0;
      giftCardPayMethods = [giftCardToPayMethod(selectedGiftCard, orderInfo as IOrderInfo, selectedGiftCard.balance)];
    }

    if (method === PayTypes.CREDIT_CARD) {
      this.setState({
        showPayCardModal: true,
        showModal: false
      });
    } else if ([PayTypes.ANONYMOUS_GIFT_CARD, PayTypes.STORE_CREDIT].includes(method)) {
      if (data) {
        const giftCard = data as IGiftCard;
        if (selectedGiftCard && selectedGiftCard.id === giftCard.id) {
          selectedGiftCard = undefined;
        } else {
          selectedGiftCard = giftCard;
        }

        this.setState({ selectedGiftCard });

        const gcPayMethod = giftCardToPayMethod(giftCard, orderInfo as IOrderInfo, 0)
        if (giftCard.balance >= amountNum) {
          dispatch({
            type: ActionType.MODIFY_PAY_METHODS,
            payMethods: [gcPayMethod]
          });
          this.handleCloseClick();
        } else {
          const { payMethods } = reducersState.pay;
          let payMethod: IPayMethod | undefined = undefined;
          for (let i = 0; i < payMethods.length; i++) {
            const tempPayMethod = payMethods[i];
            if (![PayTypes.STORE_CREDIT, PayTypes.ANONYMOUS_GIFT_CARD].includes(tempPayMethod.type)) {
              payMethod = JSON.parse(JSON.stringify(tempPayMethod));
              break;
            }
          }

          if (payMethod) {
            payMethod.amount = minus(amountNum, Number(gcPayMethod.balance));
            gcPayMethod.amount = gcPayMethod.balance ? Number(gcPayMethod.balance) : 0;
            dispatch({
              type: ActionType.MODIFY_PAY_METHODS,
              payMethods: [gcPayMethod, payMethod],
            });
            this.handleCloseClick();
          }
        }
      }
    } else if ([PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY].includes(method)) {
      if (data) {
        const creditCard = data as IPayCard;
        this.setState({ selectedMethod: `${creditCard.type}_${creditCard.id}` });

        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [
            ...giftCardPayMethods,
            creditCardToPayMethod(creditCard, orderInfo as IOrderInfo, balance),
          ],
        });
        this.handleCloseClick();
      }
    } else if (method === PayTypes.APPLE_PAY) {
      this.setState({ selectedMethod: method });
      const { gatewayAppSettings, platformAppSettings, deviceInfo } = reducersState.app;
      this.routeParams = parseRouteParams(this.props);
      const { storeSlug } = this.routeParams;
      const { amount } = this.state;
      if (gatewayAppSettings && platformAppSettings) {
        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [
            ...giftCardPayMethods,
            {
              id: PayTypes.APPLE_PAY,
              type: PayTypes.APPLE_PAY,
              countryCode: 'HK',
              currencyCode: orderInfo.currencyCode,
              supportedNetworks: gatewayAppSettings.applePayMethodKeys,
              appMerchantId: deviceInfo.isAppleApp && platformAppSettings.merchantID ? platformAppSettings.merchantID : gatewayAppSettings.merchantID,
              appMerchantDomain: gatewayAppSettings.domain,
              label: platformAppSettings.name,
              amount: minus(amountNum, balance),
            }
          ],
        });
        dispatch({
          type: ActionType.CREATE_EMPTY_ORDER,
          storeSlug,
          amount: parseFloat(amount),
          callback: (orderInfo: IOrderInfo | undefined) => {
            this.setState({ paying: false });
            if (orderInfo) {
              this.setState({ orderInfo })
            }
          }
        });
        this.handleCloseClick();
      }
    } else if ([PayTypes.ALIPAY, PayTypes.ALIPAY_HK].includes(method)) {
      this.setState({ selectedMethod: method });
      const type = method === PayTypes.ALIPAY ? PayTypes.ALIPAY : PayTypes.ALIPAY_HK;
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: type,
            currencyCode: orderInfo.currencyCode,
            type,
            returnUrl: '',
            amount: minus(amountNum, balance),
          }
        ],
      });
      this.handleCloseClick();
    } else if (method === PayTypes.WECHAT_PAY) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.WECHAT_PAY,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.WECHAT_PAY,
            returnUrl: '',
            amount: minus(amountNum, balance),
          }
        ],
      });
      this.handleCloseClick();
    } else if (method === PayTypes.TAP_GO) {
      this.setState({ selectedMethod: method });
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [
          ...giftCardPayMethods,
          {
            id: PayTypes.TAP_GO,
            currencyCode: orderInfo.currencyCode,
            type: PayTypes.TAP_GO,
            returnUrl: '',
            amount: minus(amountNum, balance),
          }
        ],
      });
      this.handleCloseClick();
    }
  }

  getPayMethod = (method: PayTypes) => {
    const paymentMethodRef: IObject = {
      [PayTypes.AMEX]: 4,
      [PayTypes.MASTER]: 4,
      [PayTypes.VISA]: 4,
      [PayTypes.CREDIT_CARD]: 4,
      [PayTypes.ANONYMOUS_GIFT_CARD]: 16,
      [PayTypes.STORE_CREDIT]: 16,
      [PayTypes.WECHAT_PAY]: 17,
      [PayTypes.APPLE_PAY]: 18,
      [PayTypes.ALIPAY]: 19,
      [PayTypes.ALIPAY_HK]: 19,
      [PayTypes.TAP_GO]: 21,
    }

    const payMethod: number = paymentMethodRef[method];

    return payMethod;
  }

  getSaleTransactions = () => {
    const { reducersState } = this.props;
    const saleTransactions: ISaleTransaction[] = [];
    const { payMethods } = reducersState.pay;

    payMethods.forEach(item => {
      saleTransactions.push({
        id: item.id || item.type,
        amount: Number(item.amount),
        currencyCode: item.currencyCode,
        // paymentMethod: payKeyRefMethod[],
        paymentMethod: this.getPayMethod(item.type),
        creditCardType: item.creditCardType || '',
        last4Digits: item.last4Digits || '',
        balance: item.balance || 0,
      });
    })
    return saleTransactions;
  }

  handlePayBill = () => {
    this.routeParams = parseRouteParams(this.props);
    const { storeSlug } = this.routeParams;
    const { dispatch, reducersState } = this.props;
    const { amount } = this.state;

    this.setState({ paying: true });

    const { payMethods } = reducersState.pay;
    const newPayMethods: IPayMethod[] = JSON.parse(JSON.stringify(payMethods));
    newPayMethods.forEach(item => {
      if (item.type === PayTypes.APPLE_PAY) {
        this.handlePay();
      } else {
        dispatch({
          type: ActionType.CREATE_EMPTY_ORDER,
          storeSlug,
          amount: parseFloat(amount),
          callback: (orderInfo: IOrderInfo | undefined) => {
            this.setState({ paying: false });
            if (orderInfo) {
              this.setState({
                orderInfo,
              }, () => {
                this.handlePay();
              })
            }
          }
        });
      }
    })
  }

  gotoPayResultsPage = (order: any) => {
    const { dispatch, history } = this.props;
    const { search } = history.location;

    dispatch({
      type: ActionType.SET_ORDER,
      orderNumber: order.number,
      order,
    });

    const query: any = {};
    if (this.routeParams.token) {
      query.token = this.routeParams.token;
    }
    if (this.routeParams.domain) {
      query.domain = this.routeParams.domain;
    }

    this.handleGotoRoute({
      path: paths.PAY_RESULTS,
      pathParams: {
        storeSlug: this.routeParams.storeSlug,
        orderNumber: order.number,
      },
      historyState: query,
      search: `?fromPage=payBill${search ? '&payBillInfo=' + search.substr(1) : ''}`,
    });
  }

  handlePay = async () => {
    const { reducersState, routeParams } = this.props;
    const { orderInfo, store } = this.state;
    const { payMethods } = reducersState.pay;
    const { domain } = reducersState.app.platformAppSettings || {};
    const token = getAccessToken();
    if (!orderInfo || !store) return;

    const storeConfig: IStoreConfig = reducersState.store.slugRefStoreConfig[store.slug];

    const {
      safetyVerification: getawapSafetyVerification = false,
    } = reducersState.app.gatewayAppSettings || {};

    const {
      safetyVerification: appSafetyVerification = false,
      safetyVerificationAmount: appSafetyVerificationAmount,
    } = reducersState.app.platformAppSettings || {};

    const {
      safetyVerification,
      safetyVerificationAmount,
    } = storeConfig

    this.setState({ paying: true });

    const newPayMethods: IPayMethod[] = JSON.parse(JSON.stringify(payMethods));
    let returnUrl = '';
    let currentPayTotal = 0;
    newPayMethods.forEach(item => {
      if (item.type === PayTypes.ALIPAY || item.type === PayTypes.ALIPAY_HK || item.type === PayTypes.TAP_GO || item.type === PayTypes.WECHAT_PAY) {
        this.routeParams = parseRouteParams(this.props);
        returnUrl = window.location.origin + createRouteUrl(
          paths.PLATFORM_PAY_BILL,
          {
            storeSlug: routeParams.storeSlug,
            platformID: routeParams.platformID,
            query: {
              token: token,
              domain: domain,
              lang: 'en',
              check: 1,
              orderNumber: orderInfo.orderNumber,
              amount: orderInfo.subTotal,
            }
          },
          this.props,
        );

        item.returnUrl = returnUrl;
      }

      // 会员用户与游客
      if (item.type === PayTypes.CREDIT_CARD || item.type === PayTypes.VISA || item.type === PayTypes.MASTER || item.type === PayTypes.AMEX || item.type === PayTypes.UNION_PAY) {
        this.routeParams = parseRouteParams(this.props);
        returnUrl = window.location.origin + createRouteUrl(
          paths.PLATFORM_PAY_BILL,
          {
            storeSlug: routeParams.storeSlug,
            platformID: routeParams.platformID,
            query: {
              token: token,
              domain: domain,
              lang: 'en',
              check: 1,
              orderNumber: orderInfo.orderNumber,
              amount: orderInfo.subTotal,
            }
          },
          this.props,
        );

        if (item.creditCardType === PayTypes.UNION_PAY || item.type === PayTypes.UNION_PAY) {
          returnUrl = `${Config.bindoHq}/redirect?url=${returnUrl}`;
        }

        item.returnUrl = returnUrl;
        item.getawapSafetyVerification = getawapSafetyVerification;
        item.appSafetyVerification = appSafetyVerification;
        item.appSafetyVerificationAmount = appSafetyVerificationAmount;
        item.safetyVerification = safetyVerification;
        item.safetyVerificationAmount = safetyVerificationAmount;
      }

      if (item.type === PayTypes.APPLE_PAY) {
        item.deviceInfo = reducersState.app.deviceInfo;
      }
      currentPayTotal = plus(currentPayTotal, Number(item.amount));
    })
    const { runPayOrder } = usePayment({
      orderNumber: orderInfo.orderNumber,
      storeID: store.id,
      payData: newPayMethods,
      extraAction: {
        onCancel: () => {
          this.setState({ paying: false });
        }
      }
    });

    const result = currentPayTotal === orderInfo.initialTotal ? await runPayOrder() : { status: 500 };
    const {
      order,
      status,
      code,
      message,
    } = result || {};

    if (order && order && order.state === 'completed') {
      this.gotoPayResultsPage(order);
    } else if (code && code === '100400' && message === 'Payment:The amount cannot be less than 0.2') { // 支付失败
      Modal.alert(getIntl().common.tips, <>{getIntl().common.payErrorTips}<div>{`[${code}]`}</div></>, [
        {
          text: getIntl().common.confirm,
          onPress: () => { },
        }
      ]);
      this.setState({ paying: false });
    } else if (status === 500) { // 支付失败
      Modal.alert(getIntl().common.tips, <>{getIntl().common.payfail}<div>{code && `[${code}]`}</div></>, [
        {
          text: getIntl().common.confirm,
          onPress: () => { },
        }
      ]);
      this.setState({ paying: false });
    }
  }

  handleLeftClick = () => {
    const { routeParams } = this.props;
    const { realDomain } = this.state;

    const { platformHost, isLocalhost } = getDomain(realDomain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;
    window.location.href = `${protocol}//${platformHost}${createRouteUrl(
      paths.MENU_LIST,
      {
        storeSlug: routeParams.storeSlug,
      },
    )}`
  }

  // 处理支持的支付方式, 平台 店铺 个人都支持才能真正支持
  handleSupportPayMethod = (): PayTypes[] => {
    const { reducersState, routeParams } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { slugRefStoreConfig } = reducersState.store;
    const currentStoreconfig = slugRefStoreConfig[routeParams.storeSlug];

    let canPayMethods: PayTypes[] = [];
    if (platformAppSettings) {
      const platformPayMethodKeys = platformAppSettings.payMethodKeys;
      const storeConfigmethodKeys = currentStoreconfig && currentStoreconfig.payMethodKeys.length > 0 ? currentStoreconfig.payMethodKeys : [PayTypes.VISA, PayTypes.MASTER, PayTypes.AMEX, PayTypes.UNION_PAY];
      canPayMethods =  sameItemInMultipleArray(platformPayMethodKeys, storeConfigmethodKeys);
    }

    return canPayMethods;
  }

  render() {
    const { showModal, amount, showPayCardModal, store, paying, initLoading, storeConfig, payBill, isFromPage } = this.state;
    const { reducersState } = this.props;
    const saleTransactions = this.getSaleTransactions();
    const {payMethods } = reducersState.pay;
    const canPayMethods = this.handleSupportPayMethod();
    return (
      <Layout
        {...this.props}
        navContent={getIntl().page.payBill}
        className={prefix}
        navIcon={ isFromPage && <Icon type={IconType.LEFT_OUTLINED} className="html-embed-7 w-embed" />}
        onLeftClick={ isFromPage? this.handleLeftClick: undefined }
        isFooter={false}
      >
        {
          (initLoading || !store) && 
          <Skeleton />
        }
        {
          ((storeConfig && !storeConfig.payBill) || !payBill) &&
          <div>
            <Icon type={IconType.STATUS_FAILURE} />
            <div className="icon-bottom">{getIntl().page.paymentNotOpened}</div>
          </div>
        }
        <PayBillPageStyled>
          {
            ((storeConfig && storeConfig.payBill) || (payBill && payBill)) &&
            <div>
              {
                !initLoading &&
                <>
                  <div className="section pay-bill wf-section">
                    <div className="container narr0w w-container">
                      <div className="columns-3 w-row">
                        <div className="column-10 w-col">
                          <div className="people-info">
                            <div className="text-block-104">{store && (getI18nText(store, 'title', reducersState.app.language) || store.name)}</div>
                            <div className="_107">{store && (getI18nText(store, 'address1', reducersState.app.language) || store.address)} </div>
                          </div>
                        </div>
                      </div>
                      <div className="div-block-615">
                        <div className="div-block-617">
                          <div className="text-block-67 bold">{getIntl().common.paymentAmount}</div>
                        </div>
                        <div className="div-block-616">
                          <div className="text-block-227">{formatCurrencyCode(store && store.currencyCode)}</div>
                          <InputItem
                            placeholder={getIntl().page.enterPayment}
                            onChange={this.handleAmountChange}
                            onBlur={this.handlePrecentile}
                            value={this.parseFormat(amount)}
                            className="text-block-228 inactive"
                          ></InputItem>
                        </div>
                      </div>
                      <div className="div-block-615-none">
                        <PayInfo
                          title={getIntl().common.payment}
                          className={`${prefix}-content-pay-info-wapper`}
                          showItemLeftIcon={true}
                          showBalance={true}
                          saleTransactions={saleTransactions}
                          onItemClick={() => {
                            return amount ? this.setState({showModal: true}) : () => {}
                          }}
                          onAddPayMethod={amount ? this.handlePayMethod : () => {}}
                          isPayBillPage={true}
                        />
                      </div>
                      <div className="div-block-615 less-space">
                        <div className="div-block-617 more-space">
                          <div className="text-block-67 bold">Bill Reference(Optional)</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="float-block-pay-bill show-all">
                    <div className="float-navigation wf-section">
                      <div className="container w-container">
                        <div className="floating-big-btn w-inline-block">
                          <Button
                            type="primary"
                            className="text-block-17"
                            onClick={this.handlePayBill}
                            disabled={!amount || parseFloat(amount) <=0 || payMethods.length <= 0}
                          >
                            {getIntl().page.confirmAndPay}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
              {
                !initLoading &&
                <PayBillMethodModal
                  visible={showModal}
                  onCancel={() => this.setState({ showModal: false })}
                  canPayMethods={canPayMethods}
                  handleItemClick={this.handleItemClick}
                />
              }
              {
                !initLoading &&
                <PayCardModal
                  dispatch={this.props.dispatch}
                  visible={showPayCardModal}
                  onCancel={() => this.setState({ showPayCardModal: false })}
                  onAddcarded={() => {
                    this.setState({ showPayCardModal: false, showModal: false })
                    this.initPayMethod();
                  }}
                  store={store}
                  isGuest={true}
                  amount={amount}
                  reducersState={this.props.reducersState}
                />
              }
              {
                paying &&
                <div className="co-checking-wrapper">
                  <div className="co-checking">
                    <div className="co-checking-loading">
                      <AntdIcon type="loading" />
                    </div>
                    <div className="co-checking-text">
                      {getIntl().page.paying}
                    </div>
                  </div>
                </div>
              }
            </div>
          }
        </PayBillPageStyled>
      </Layout>
    );
  }
}

export default PayBillPage;
