export default {
  oePay: 'O!ePay',
  weChatPay: 'WeChat Pay',
  applePay: 'Apple Pay',
  googlePay: 'Google Pay',
  alipay: 'Alipay',
  alipayHK: 'Alipay HK',
  tapGoPay: 'Tap & Go',
  unionPay: 'Union Pay',
  cardEnding: 'Card Ending',
  giftCard: 'Gift Card',
  creditCard: 'Credit Card',
  deferredBilling: 'Deferred Billing',
  cashOnDelivery: 'Cash On Delivery',
}
