import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import TextareaItem from 'antd-mobile/es/textarea-item';
import Icon, { IconType } from '@/components/Icon';
import { formatCurrency } from '@/components/Payment';
import { IDispatch } from '@/actions';
import OrderEntity, { IOrderInfo, ISetShippingMethodParams } from '@/lib/order-builder/models/OrderEntity';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import { prefix } from '.';
import ListingsItem from './ListingsItem';
import dayjs from 'dayjs';
import { IReducersState } from '@/reducers';
import ActionType from '@/actions/action-type';
import ActionBtns from '../ActionBtns/ActionBtns';
import { useHistory } from 'react-router-dom';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import ProcurementMethod from '../ProcurementMethod/ProcurementMethod';
import { IStore, IStoreTable, IStorePickupLocation } from '@/actions/store-action';
import { getI18nText, parseOpeningHours, langFormat } from '@/utils/app';
import PaymentMethodPanel from './PaymentMethodPanel';
import { IShoppingCartData } from '@/actions/shopping-cart-action';
import { goToRoute } from '@/utils/route-util';
import { createRouteUrl, findNode } from '@/utils';
import paths from '@/routes/paths';
import SmartDiscountEngine from './SmartDiscountEngine';
import { getIntl, getIntlByPath } from '../App/App';
import { unstable_batchedUpdates  as unstableBatchedUpdates } from 'react-dom';

interface IListingsPanelProps {
  data: OrderEntity;
  className?: string;
  showStoreTitle?: boolean;
  showRightTopCount: boolean;
  enableNotes?: boolean;
  onPlusClick?: (data: LineItemEntity) => void;
  onMinusClick?: (data: LineItemEntity) => void;
  setNote?: (storeID: string, note: string) => void;
  setDate?: (storeID: string, date: Date) => void;
  itemType?: React.ReactNode;
  itemTypeTime?: string;
  reducersState: IReducersState;
  showStoreInfo?: boolean;
  orderInfo?: IOrderInfo;
  showPickupLocation?: boolean;
  handleProcurementMethodChange?: (data: ISetShippingMethodParams, store?: IStore) => void;
  hideChangeMethod?: boolean;
  onItemClick?: (storeSlug: string, isSplitOrder: boolean) => void;
  showCheckoutBtn?: boolean;
  showChange?: boolean;
  showTotal?: boolean;
  forExpressOrder?: boolean;
  confirmPage?: boolean;
  handleChangeQuantityTo?: (data: LineItemEntity, quantity: number) => void;
  handlePromotionCode?: (storeID: string, discountScript: string[], promotionCode: string, promotionName: string, membershipLevelID: string, cancel?: boolean) => void;
}

const ListingsPanel: React.FC<IListingsPanelProps> = props => {
  const {
    data,
    className = '',
    showStoreTitle = false,
    showRightTopCount = false,
    enableNotes = false,
    onPlusClick,
    onMinusClick,
    itemType,
    itemTypeTime = '',
    reducersState,
    showStoreInfo = false,
    setNote,
    orderInfo,
    showPickupLocation = false,
    handleProcurementMethodChange,
    hideChangeMethod = false,
    showCheckoutBtn = false,
    showChange = false,
    onItemClick,
    showTotal = false,
    forExpressOrder = false,
    confirmPage = false,
    handleChangeQuantityTo,
    handlePromotionCode,
  } = props;
  const dispatch = useDispatch<IDispatch>();
  const [storeName, setStoreName] = useState('');
  const [show, setShow] = useState(false);
  // const [height, setHeight] = useState(0);
  const heightDiv = useRef<HTMLDivElement | null>(null);
  const animationDiv = useRef<HTMLDivElement | null>(null);
  const [storeSlug, setStoreSlug] = useState('');
  const [store, setStore] = useState<IStore>();
  const [storeTables, setStoreTables] = useState<IStoreTable[]>();
  const [addressShow, setAddressShow] = useState(false);
  const [shoppingCartData, setShoppingCartData] = useState<IShoppingCartData>();
  const [currentLocationID, setCurrentLocationID] = useState<string>();
  // const [pickupLocations, setPickupLocations] = useState();
  const { suppliers } = reducersState.app.platformAppSettings || {};
  const shippingMethodKV: any = {
    sm0: 'takeAway',
    sm1: 'delivery',
    sm2: 'dineIn',
    sm3: 'dineIn',
  }

  useEffect(() => {
    const store = findNode<IStore>(Object.values(reducersState.store.slugRefStore), 'id', data.storeID);
    unstableBatchedUpdates(() => {
      if (store) {
        setStoreName(store.name);
        setStoreSlug(store.slug);
        setStore(store);
        if (!suppliers) {
          const storeTables = reducersState.store.slugRefTables[store.slug];
          setStoreTables(storeTables);
        }
      }
      setTimeout(() => {
        if (heightDiv.current && animationDiv.current) {
          // setHeight(heightDiv.current.offsetHeight);
          if ((showStoreTitle || showStoreInfo) && data.lineItems.length > 0) {
            if (suppliers) {
              animationDiv.current.className = 'animation';
            }
          } else {
            setShow(true);
          }
        }
      }, 30)
      //setShow(suppliers ? false : true);
      if (suppliers) {
        setShow(false);
        if (confirmPage) {
          setShow(true);
        }
      } else {
        setShow(true);
      }
    })
  }, [showStoreTitle, showStoreInfo]);
  const { slugRefStorePickupLocations } = reducersState.store;
  const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
  const history = useHistory();
  const handleItemClick = () => {
    setAddressShow(true)
    dispatch({
      type: ActionType.GET_STORE_SHOPPING_CART_DATA,
      storeSlug: storeSlug,
      callback: params => {
        const { shoppingCartData } = params;
        setShoppingCartData(shoppingCartData)
        if (shoppingCartData) {
          setCurrentLocationID(shoppingCartData.orderData.pickup_location_id || '')
        }
      },
    });
  }
  const handleGoSelectAddress = () => {
    goToRoute({
      history,
      pathname: createRouteUrl(paths.STORE_ADDRESS_LIST, { storeSlug }),
      clearPaths: false,
    });
  }
  const chooseLocation = (item: IStorePickupLocation) => {
    setCurrentLocationID(item.id)
    dispatch({
      type: ActionType.CHANGE_SHIPPING_METHOD,
      storeSlug: storeSlug,
      data: {
        shippingMethod: ShippingMethod.PICK_UP,
        pickupLocationID: item.id,
        pickupLocation: item,
        deliveryDate: dayjs(shoppingCartData?.orderData.delivery_date),
      },
    });
    setAddressShow(false);
    // goToRoute({
    //   history,
    //   pathname: createRouteUrl(paths.HOME),
    //   clearPaths: false,
    // });
  }
  const { slugRefStoreConfig } = reducersState.store;
  const storeConfig = slugRefStoreConfig[storeSlug];
  const { openingHours } = store || {};

  const parseOpeningHoursResult = openingHours ? parseOpeningHours(openingHours) : undefined;

  const handleDelete = () => {
    if (forExpressOrder) {
      dispatch({
        type: ActionType.SET_EXPRESS_ORDER_FOR_ZERO,
        storeID: data.storeID,
      });
    } else {
      dispatch({
        type: ActionType.CLEAR_STORES_SHOPPING_CART,
        storeIDs: [data.storeID],
      });
    }
  }

  const getShippingTime = (data: OrderEntity) => {
    let shippingTime: React.ReactNode = getIntl().page.immediately;
    if (data && data.deliveryDate) {
      const ddTime = dayjs(data.deliveryDate);
      const dateFormat = ddTime.format('YYYY-MM-DD');
      const todayFormat = dayjs(new Date()).format('YYYY-MM-DD');
      shippingTime = dayjs(data.deliveryDate).format('H:mmA');
      if (dateFormat === todayFormat) {
        shippingTime = (
          <>
            {getIntl().common.today}
            <span className="shipping-time">{shippingTime}</span>
          </>
        );
      } else if (data.shippingMethod === ShippingMethod.DINE_IN && orderInfo) {
        const dineInParty = orderInfo.dineInParty;
        if (dineInParty) {
          shippingTime = langFormat(getIntl().page.tableNumber, { tableNumber: dineInParty.tableName });
        }
      } else {
        shippingTime = (
          <>
            <span>{dateFormat}</span>
            {
              ((storeConfig && storeConfig.deliveryInterval !== 0 && data.shippingMethod === ShippingMethod.DELIVERY) || (storeConfig && storeConfig.pickUpInterval !== 0 && data.shippingMethod === ShippingMethod.PICK_UP)) &&
              <span className="shipping-time">{shippingTime}</span>
            }
          </>
        );
      }
    }

    return shippingTime;
  }

  const getLimeQuantity = () => {
    let count = 0;
    data.lineItems.forEach(item => {
      count += item.quantity;
    })
    return count;
  }

  let isShow = true;
  if (suppliers && confirmPage && (data.orderShippingAddress && !data.orderShippingAddress.id)) {
    isShow = false;
  }


  return (
    <div className={`${prefix} ${className}`}>
      <div className="order-block">
        {
          (showStoreTitle || showStoreInfo) && data.lineItems.length > 0 &&
          <div className={`check-out-info restaurant-info ${suppliers? 'info-no-border': ''}`} style={suppliers ? {borderBottom: show ? '': '0px'} : undefined}>
            <div className="text-block-67 bold">
              {store && (getI18nText(store, 'title', reducersState.app.language) || storeName)}
            </div>
            {
              suppliers &&
              <div className="div-block-487" onClick={() => setShow(!show)}>
                <div className="text-block-69">{getIntl().invoice.viewDetails}</div>
                <Icon className="html-embed-34 rotated w-embed" type={show ? IconType.UP_OUTLINED : IconType.DOWN_OUTLINED} />
              </div>
            }
          </div>
        }
        <div key={data.storeID} ref={animationDiv} className={suppliers ? 'animation' : ''} style={suppliers ? {maxHeight: show ? '': '0px', overflow: show ? 'hidden': ''} : undefined}>
          <div ref={heightDiv}>
            {
              itemType &&
              <div className="check-out-info with-space-1">
                {/* <div className="item-type-wrap check-out-info with-space-1"> */}
                <div className="text-block-67 bold">{itemType}</div>
                {
                  itemTypeTime &&
                  <div className="item-type-time">{dayjs(itemTypeTime).format('H:mm:ss')}</div>
                }
              </div>
            }
            {
              storeConfig && storeConfig.delayedDeliveryTime !== 0 &&
              <div className="div-block-501 no-line">
                <Icon type={IconType.TIME_SOLID_OUTLINED} className="html-embed-38 w-embed" />
                <div className="text-block-67 bold">
                  {langFormat(getIntl().page.advanceOrderTips, { day: storeConfig.delayedDeliveryTime })}
                </div>
              </div>
            }
            {
              !itemType &&
              <div className="tinted-background">
                {
                  data.shippingMethod === ShippingMethod.DELIVERY && orderInfo && storeConfig && storeConfig.deliveryMinAmount > 0 && 
                  <div className="check-out-info delivery">
                    {/* <div className="line-item" style={{borderBottom: '1px solid var(--bm-border-color)', marginBottom:'10px'}}> */}
                    <div className="text-block-67">{getIntl().page.delivery}</div>
                    <div className="div-block-487 open-modal">
                      <div className="text-block-69">
                        {(storeConfig && storeConfig.deliveryTips) || getI18nText(storeConfig, 'delivery_tips', reducersState.app.language)}
                      </div>
                    </div>
                  </div>
                }
                {
                  !showChange &&
                  <div className="check-out-info delivery">
                    <div className="text-block-67">
                      {
                        data && getIntlByPath(`page.${shippingMethodKV['sm' + data.shippingMethod] || ''}`)
                      }
                    </div>
                    <div className="div-block-487 open-modal">
                      <div className="text-block-69">{ data && getShippingTime(data) }</div>
                    </div>
                  </div>
                }
                {
                  showChange &&
                  <div className="div-block-508">
                    <ProcurementMethod
                      history={history}
                      id="bm-menus-dining-style-for-cart"
                      className="dining-style"
                      orderInfo={orderInfo}
                      store={store}
                      storeConfig={storeConfig}
                      currentShippingMethod={data.shippingMethod}
                      parseOpeningHoursResult={parseOpeningHoursResult}
                      onChange={handleProcurementMethodChange}
                      hideChangeMethod={hideChangeMethod}
                      storeTables={storeTables}
                      showChange={showChange}
                    />
                  </div>
                }
                {
                  pickupLocations.length > 0 && data.shippingMethod === ShippingMethod.PICK_UP &&
                  <div className="div-block-508">
                    <div className="text-block-67">{getIntl().page.pickupAddress}</div>
                    <div className="address-fields no-line open-modal" onClick={showPickupLocation ? handleItemClick : undefined}>
                      <Icon type={IconType.MAP_OUTLINED} className="html-embed-25 w-embed" />
                      {
                        data.pickupLocation &&
                        <div className="div-block-488">
                          <div className="text-block-77">{getI18nText(data.pickupLocation, 'name', reducersState.app.language) || (data.pickupLocation && data.pickupLocation.name)}</div>
                          <div className="text-block-69"></div>
                        </div>
                      }
                      {
                        showPickupLocation &&
                        <div className="div-block-487">
                          <Icon className="html-embed-34 w-embed" type={IconType.RIGHT_OUTLINED} />
                        </div>
                      }
                    </div>
                  </div>
                }
                {
                  data.shippingMethod === ShippingMethod.DELIVERY && (!suppliers || isShow) &&
                  <div className="div-block-508">
                    <div className="text-block-67">{getIntl().page.deliveryAddress}</div>
                    <div className="address-fields no-line open-modal" onClick={showPickupLocation ? handleGoSelectAddress : undefined}>
                      <Icon type={IconType.MAP_OUTLINED} className="html-embed-25 w-embed" />
                      {
                        data.orderShippingAddress && data.orderShippingAddress.id &&
                        <div className="div-block-488">
                          <div className="text-block-77">
                            {data.orderShippingAddress.city || ''}{data.orderShippingAddress.address1 || data.orderShippingAddress.address_1 || ''}
                            <div className="delivery-name">
                              {data.orderShippingAddress.name || data.orderShippingAddress.full_name || ''} {data.orderShippingAddress.phone || ''}
                            </div>
                          </div>
                          <div className="text-block-69"></div>
                        </div>
                      }
                      {
                        (!data.orderShippingAddress || !data.orderShippingAddress.id) &&
                        <div className="div-block-487">
                          <div className="delivery-name order-shipping-address">
                            {getIntl().page.chooseLocation}
                          </div>
                        </div>
                      }
                      {
                        showPickupLocation &&
                        <div className="div-block-487">
                          <Icon className="html-embed-34 w-embed" type={IconType.RIGHT_OUTLINED} />
                        </div>
                      }
                    </div>
                  </div>
                }
                
                {
                  suppliers && storeConfig && storeConfig.payMethodKeys.length > 0 && showChange &&
                  <PaymentMethodPanel data={storeConfig.payMethodKeys} />
                }
              </div>
            }
            {
              data.lineItems.map((item, index) => (
                <>
                  <ListingsItem
                    key={item.uuid || item.id || ''}
                    data={item}
                    showRightTopCount={showRightTopCount}
                    onPlusClick={onPlusClick}
                    onMinusClick={onMinusClick}
                    reducersState={reducersState}
                    handleChangeQuantityTo={handleChangeQuantityTo}
                  />
                  {
                    (index === data.lineItems.length - 1 && !itemType) &&
                    <div className="div-block-483"></div>
                  }
                </>
              ))
            }
            {
              data.invalidLineItems.length > 0 &&
              <>
                <div className="invalid-line-item-tips">
                  {getIntl().page.invalidLineItemTips}
                </div>
                {
                  data.invalidLineItems.map(item => (
                    <ListingsItem
                      key={item.uuid || item.id || ''}
                      data={item}
                      showRightTopCount={showRightTopCount}
                      style={{ opacity: 0.4 }}
                      reducersState={reducersState}
                    />
                  ))
                }
              </>
            }
            {
              setNote && enableNotes &&
              <div className="remark-block-tablet">
                <div className="text-block-90">
                  {getIntl().page.remark}
                </div>
                <TextareaItem
                  className={`${prefix}-remark-textarea-item div-block-502`}
                  placeholder={getIntl().page.shippingCarNotePlaceholder}
                  rows={3}
                  onChange={value => {value && setNote(data.storeID, value)}}
                  defaultValue={data.note}
                  count={100}
                ></TextareaItem>
              </div>
            }
            {
              !setNote && data && data.note &&
              <div className={`${prefix}-remark`}>
                <div className={`${prefix}-remark-title`}>{getIntl().page.remark}</div>
                <div className={`${prefix}-remark-note`}>{data.note}</div>
              </div>
            }
            {
              !confirmPage && 
              <SmartDiscountEngine
                data={data}
                storeSlug={storeSlug}
                reducersState={reducersState}
                handlePromotionCode={handlePromotionCode}
              />
            }
            {
              showTotal &&
              <div className="div-block-493">
                <div className="div-block-484">
                  <div className="text-block-98">{getIntl().common.subtotal}</div>
                  {
                    data &&
                    <div className="text-block-68">{formatCurrency(data.subtotal, store && store.currencyCode)}</div>
                  }
                </div>
                {
                  data.discountTotal !== 0 &&
                  <div className="div-block-484">
                    <div className="text-block-98">
                      {getIntl().common.discount}
                    </div>
                    {
                      data &&
                      <div className="text-block-68">
                        -{formatCurrency(data.discountTotal, store && store.currencyCode)}
                      </div>
                    }
                  </div>
                }
                {
                  data && data.initialServiceFee !== 0 &&
                  <div className="div-block-484">
                    <div className="text-block-98">
                      {getIntl().common.serviceFee}
                    </div>
                    <div className="text-block-68">
                      {formatCurrency(data.initialServiceFee, store && store.currencyCode)}
                    </div>
                  </div>
                }
                {
                  data && data.initialTax !== 0 &&
                  <div className="div-block-484">
                    <div className="text-block-98">
                      {getIntl().common.taxFee}
                    </div>
                    <div className="text-block-68">
                      {formatCurrency(data.initialTax, store && store.currencyCode)}
                    </div>
                  </div>
                }
                {
                  data && data.initialRounding !== 0 &&
                  <div className="div-block-484">
                    <div className="text-block-98">
                      {getIntl().common.rounding}
                    </div>
                    <div className="text-block-68">
                      {formatCurrency(data.initialRounding, store && store.currencyCode)}
                    </div>
                  </div>
                }
                <div className="div-block-484">
                  <div className="text-block-67 bold">{getIntl().common.total}</div>
                  {
                    data &&
                    <div className="text-block-68 bold">{formatCurrency(data.initialTotal, store && store.currencyCode)}</div>
                  }
                </div>
                {
                  data.shippingMethod === ShippingMethod.DELIVERY && orderInfo && storeConfig && storeConfig.deliveryMinAmount > 0 &&
                  <div className="delivery-moq" style={{color: data.initialTotal >= storeConfig.deliveryMinAmount ? 'var(--bm-tag-delivery-color)' : 'var(--bm-error-color)'}}>
                    {(storeConfig && storeConfig.deliveryTips) || getI18nText(storeConfig, 'delivery_tips', reducersState.app.language)}
                  </div>
                }
              </div>
            }
            {
              data.lineItems.length > 0 && showChange && (suppliers || showCheckoutBtn) && false &&
              <div className="div-block-512">
                {
                  suppliers &&
                  <ActionBtns 
                    textAlign={'left'}
                    data={[
                      {
                        value: '',
                        iconType: IconType.RECYCLE_BIN_OUTLINED,
                        onClick: handleDelete,
                        className: `${prefix}-actions-remove rubbish`,
                      },
                      // {
                      //   value: '',
                      //   iconType: IconType.SHARE_OUTLINED,
                      //   onClick: handleClick,
                      //   className: `${prefix}-actions-share share`,
                      // },
                    ]}
                  />
                }
                {
                  showCheckoutBtn &&
                  <div className={`individual-checlout w-inline-block ${getLimeQuantity() === 0 ? 'checkout-btn-wrap-disable' : ''}`}
                    onClick={() => {
                      if (onItemClick && getLimeQuantity() !== 0) {
                        onItemClick(storeSlug, true);
                      }
                    }}
                  >
                    <div className="text-block-17" >
                      {getIntl().page.checkoutTitle}
                    </div>
                  </div>
                }
              </div>
            }
          </div>
        </div>
      </div>
      <div className={`modal-wrapper-address ${addressShow?'adress-show': ''} `}>
        <div className="c-popup-close-2 close-modal" onClick={() => setAddressShow(false)}></div>
        <div className="flex-pop-up">
          <div className="top-header widen">
            <h2 className="heading-2">{getIntl().page.chooseLocation}</h2>
            <div className="x-icon close-modal" onClick={() => setAddressShow(false)}>
              <Icon className="x-icon close-modal close w-embed" type={IconType.CLOSE_OUTLINED}/>
            </div>
          </div>
          <div className="popup-body-content _2">
            <div className="div-block-497">
              {
                pickupLocations.length > 0 && pickupLocations.map(item => (
                  <div className="choose-existing-address close-modal" key={item.id} onClick={() => { chooseLocation(item) }}>
                    <div className="text-block-77">
                      {getI18nText(item, 'name', reducersState.app.language) || item.name}
                    </div>
                    <div className="div-block-487">
                      <Icon className="html-embed-41 w-embed" type={currentLocationID === item.id ? IconType.CHECKED_OUTLINED : IconType.UN_SELECTED_OUTLINED} />
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      {/*
      {
        data.shippingMethod === ShippingMethod.DELIVERY && orderInfo && storeConfig && storeConfig.deliveryMinAmount > 0 && storeConfig.deliveryMinAmount > data.initialTotal &&
        <div className="delivery-tips">
          {(storeConfig && storeConfig.deliveryTips) || getI18nText(storeConfig, 'delivery_tips', reducersState.app.language)}
        </div>
      }
      */}
    </div>
  );
}

export default ListingsPanel;
