import { takeEvery, put, select, call } from 'redux-saga/effects';
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import Config from '@/Config';
import Action from '@/actions';
import Constants from '@/constants';
import ActionType from '@/actions/action-type';
import { IReducersState } from '@/reducers';
import { IOrderState } from '@/reducers/order-reducer';
import OrderBuilder from '@/lib/order-builder';
import {
  IAddToShoppingCartByOrder,
  IShoppingCartData,
  IPushListingToShoppingCart,
  IModifyLineItemQuantity,
  IChangeShippingMethod,
  IGetStoreShoppingCartData,
  ISetStoreShoppingCartNote,
  IGetExpressOrderData,
  ISetExpressOrderForZero,
  ISetDiscountValue,
} from '@/actions/shopping-cart-action';
import { IStore, IStoreConfig } from '@/actions/store-action';
import { handleQueryStore, handleQueryStoreByID, handleQueryStorePickupLocations } from './store-saga';
import { choiceStoreID, getLocalStorageJson, findNode } from '@/utils';
import { IObject } from '@/components/bm-common';
import { IShoppingCartState } from '@/reducers/shopping-cart-reducer';
import { handleQueryStoresListings, handleQueryFavoriteListings } from './listing-saga';
import { handleQueryStoreTaxOptions, handleQueryStoreRoundingOptions } from './order-saga';
import { IOrderEntityJson, ITaxOption } from '@/lib/order-builder/models/OrderEntity';
import { getDifferentShippingMethodStore } from '@/utils/app';
import { IFavoriteListingInfo } from '@/actions/listing-action';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { IFavoriteTab } from '@/lib/order-builder/utils/api';
import dayjs from 'dayjs';

function* handleAddToShoppingCartByOrder(params: IAddToShoppingCartByOrder) {
  const { orderNumber, storeID, isSplitOrder, callback, suborderNumber } = params;
  let shoppingCartData: IShoppingCartData | undefined;
  const allShoppingCartData: IShoppingCartData[] = [];
  const orderState: IOrderState = yield select((state: IReducersState) => state.order);

  try {
    let orderData: any;

    if (orderState.orderNumberRefOrder[orderNumber]) {
      orderData = orderState.orderNumberRefOrder[orderNumber];
    }

    if (!orderData) {
      const orderRes: any = yield call(
        axios.get,
        Config.urls.getInvoices.replace('{storeID}', storeID).replace('{orderNumber}', orderNumber),
      );

      if (orderRes && orderRes.success === true && orderRes.invoice) {
        orderData = orderRes.invoice;
        yield put<Action>({
          type: ActionType.SET_ORDER,
          orderNumber,
          order: orderData,
        });
      }
    }

    if (orderData) {
      const storeIDs = OrderBuilder.getStoreIDsByOrderApiData(orderData);
      let stores: IStore[] = [];
      let queryListingsResult;
      if (Array.isArray(storeIDs) && storeIDs.length > 0) {
        queryListingsResult = yield handleQueryStoresListings({
          type: ActionType.QUERY_STORES_LISTINGS,
          storeIDs,
        });

        if (queryListingsResult && Array.isArray(queryListingsResult.stores)) {
          stores = queryListingsResult.stores;
        }
      }

      const orders: any[] = [];
      const aa: {[groupParentID: string]: any} = {};

      if (Array.isArray(orderData.sub_orders) && orderData.sub_orders.length > 0) {
        const subOrders: any[] = orderData.sub_orders;
        const newSubOrders: any[] = [];
        const superOrderStoreID = `${orderData.store_id}`;
        orderData = {
          ...orderData,
          'sub_orders': [],
        };
        if (suborderNumber) {
          subOrders.forEach(item => {
            if (item.number === suborderNumber) {
              orders.push(item);
            }
          })
        } else {
          subOrders.forEach(item => {
            const itemStoreID = item.store_id;
            const itemStore = findNode<IStore>(stores, 'id', itemStoreID);
            if (itemStore && `${itemStore.groupParentID}` !== superOrderStoreID) {
              if (!aa[itemStore.groupParentID]) {
                aa[itemStore.groupParentID] = [];
              }
  
              aa[itemStore.groupParentID].push(item);
              // TODO 检查订单信息是否需要从总订单复制（orderData）
              orders.push(item);
            } else {
              newSubOrders.push(item);
            }
          });
  
          if (newSubOrders.length > 0) {
            orderData = {
              ...orderData,
              'sub_orders': newSubOrders,
            };
            orders.push(orderData);
          }
        }
      } else {
        orders.push(orderData);
      }

      // TODO 组合超级订单

      // const { store } = yield handleQueryStoreByID({
      //   type: ActionType.QUERY_STORE_BY_ID,
      //   storeID,
      // });

      // const choiceResult = choiceStoreID(store.id, store.groupParentID);

      for (let i = 0; i < orders.length; i++) {
        const order = cloneDeep(orders[i]);

        const { store } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: order.store_id,
        });

        let taxOptions: ITaxOption[] = [];
        let roundingOption: any;
        if (store) {
          taxOptions = yield handleQueryStoreTaxOptions({
            type: ActionType.QUERY_STORE_TAX_OPTIONS,
            storeSlug: store.slug,
          });
          roundingOption = yield handleQueryStoreRoundingOptions({
            type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
            storeSlug: store.slug,
          });
        }

        const storeIDRefTaxOptions: { [storeID: string]: ITaxOption[] } = {};

        for (let i = 0; i < order.listing_line_items.length; i++) {
          const lineItem = order.listing_line_items[i];
          if (lineItem && lineItem.store_id) {
            const { store: lineItemStore } = yield handleQueryStoreByID({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID: lineItem.store_id,
            });
            if (lineItemStore) {
              const taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
                type: ActionType.QUERY_STORE_TAX_OPTIONS,
                storeSlug: lineItemStore.slug,
              });
              storeIDRefTaxOptions[lineItemStore.id] = taxOptions;
            }
          }
          const favoriteTabID = lineItem.favorite_tab_id;
          const currentMenu = findNode<IFavoriteTab>(queryListingsResult.storeIDRefListings[order.store_id].favoriteTabs, 'id', favoriteTabID || '');
          const date = dayjs();
          const currentDay = date.day();
          const currentHHmmss = dayjs().format('HH:mm:ss');
          let sectionOpen = false;
          if ((currentMenu && currentMenu.availableDays.includes(currentDay)
          && currentMenu.availableTimeFrom <= currentHHmmss
          && currentHHmmss <= currentMenu.availableTimeTo)
          || !currentMenu) {
            sectionOpen = true;
          }
          if ((!sectionOpen) && order) {
            const lineItems = order.listing_line_items.filter((item: any) => item.product_id !== lineItem.product_id);
            order['listing_line_items'] = lineItems;
          }
        }

        // let isSuperOrder = false;
        // for (let i = 0; i < order.listing_line_items.length; i++) {
        //   const lineItems = order.listing_line_items[i];
        //   if (String(lineItems.store_id) !== String(order.store_id)) {
        //     isSuperOrder = true;
        //     break;
        //   }
        // }

        // const initData: any = OrderBuilder.initStoreOrderEntity({
        //   storeID: order.store_id,
        //   isSuperOrder,
        //   currencyCode,
        //   orderEntityJson,
        //   taxOptions,
        //   storeIDRefTaxOptions,
        // });

        const initData = OrderBuilder.initStoreOrderEntityByOrderApiData({
          orderData: order,
          type: 'NEXT_ORDER',
          taxOptions,
          roundingOption,
        });

        if (initData) {
          allShoppingCartData.push(initData);
          shoppingCartData = initData;

          if (!isSplitOrder) {
            yield put<Action>({
              type: ActionType.SET_STORE_SHOPPING_CART,
              storeID: order.store_id,
              shoppingCartData: initData,
            });
          }
        }
      }

      // const { store } = yield handleQueryStoreByID({
      //   type: ActionType.QUERY_STORE_BY_ID,
      //   storeID: orderData.store_id,
      // });

      // let taxOptions: ITaxOption[] = [];
      // if (store) {
      //   taxOptions = yield handleQueryStoreTaxOptions({
      //     type: ActionType.QUERY_STORE_TAX_OPTIONS,
      //     storeSlug: store.slug,
      //   });
      // }

      // console.info(stores, orderData)
      // const storesForOder: IStoreForOder[] = [];
      // stores.forEach(item => {
      //   storesForOder.push({
      //     id: item.id,
      //     groupParentID: item.groupParentID,
      //   })
      // })

      // shoppingCartData = OrderBuilder.initStoreOrderEntityByOrderApiData({
      //   orderData,
      //   type: 'NEXT_ORDER',
      //   taxOptions,
      // });

      // if (shoppingCartData) {
      //   yield put<Action>({
      //     type: ActionType.CLEAR_SHOPPING_CART,
      //   });
      //   yield put<Action>({
      //     type: ActionType.SET_STORE_SHOPPING_CART,
      //     storeID: orderData.store_id,
      //     shoppingCartData,
      //   });
      // }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(storeID, shoppingCartData);
  }

  return shoppingCartData;
}


export function* handlePushListingToShoppingCart(params: IPushListingToShoppingCart) {
  const {
    storeSlug,
    listingID,
    modifierCountRef,
    shippingMethod,
    quantity = 1,
    clearDifferentData = false,
    pickupLocation,
    pickupLocationID,
    deliveryDate,
    forExpressOrder = false,
    disableServiceCharge = false,
    callback,
  } = params;

  let shoppingCartData: IShoppingCartData | undefined = undefined;
  let storeID: string | undefined = undefined;
  let storeIDs: string[] = [];
  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });


    const store: IStore | undefined = queryStoreResult.store;

    if (store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);
      const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
      storeIDs = getDifferentShippingMethodStore(
        store.id,
        forExpressOrder ? cartState.storeIDRefExpressOrderData : cartState.storeIDRefShoppingCartData,
        shippingMethod
      );

      if (storeIDs.length === 0 || clearDifferentData) {
        const pathRefCount: IObject = {};
        if (modifierCountRef && cartState.preAddedModifiers.listingID === listingID) {
          const tempPathRefCount = cartState.preAddedModifiers.pathRefCount;
          Object.keys(tempPathRefCount).forEach(key => {
            const paths = key.split('-');
            if (paths.length > 0 && modifierCountRef[paths[0]]) {
              pathRefCount[key] = tempPathRefCount[key];
            }
          })
        }
        let currencyCode = '';
        if (store.groupParentID && store.groupParentID !== store.id) {
          const { store: parentStore } = yield handleQueryStoreByID({
            type: ActionType.QUERY_STORE_BY_ID,
            storeID: store.groupParentID,
          });

          if (parentStore) {
            currencyCode = parentStore.currencyCode;
          }
        } else if (store.groupParentID === store.id) {
          currencyCode = store.currencyCode;
        }

        const taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
          type: ActionType.QUERY_STORE_TAX_OPTIONS,
          storeSlug,
        });
        const roundingOption: any = yield handleQueryStoreRoundingOptions({
          type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
          storeSlug,
        });

        shoppingCartData = OrderBuilder.addToCartForPaths({
          storeID: choiceResult.storeID,
          isSuperOrder: choiceResult.isSuperOrder,
          listingStoreID: store.id,
          currencyCode,
          taxOptions,
          quantity,
          shippingMethod,
          disableServiceCharge,
          pickupLocation,
          pickupLocationID,
          deliveryDate,
          roundingOption,
          paths: {
            listingID,
            ...pathRefCount,
          },
        });

        storeID = choiceResult.storeID;

        if (!forExpressOrder && shoppingCartData) {
          yield put<Action>({
            type: ActionType.ADD_TO_SHOPPING_CART,
            storeID: choiceResult.storeID,
            shoppingCartData,
            forExpressOrder,
          });
        }

      }
    }

  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(shoppingCartData, storeIDs);
  }

  return {shoppingCartData, storeID};
}

function* handleModifyLineItemQuantity(params: IModifyLineItemQuantity) {
  const { storeSlug, storeID, uuid, operate, quantity = 1, forExpressOrder = false, disableServiceCharge = false, callback } = params;

  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
  let shoppingCartData: IShoppingCartData | undefined;
  let queryStoreResult;

  try {
    if (storeID) {
      queryStoreResult = yield handleQueryStoreByID({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID,
      });
    } else {
      queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });
    }
    const taxOptions: ITaxOption[] = forExpressOrder ? [] :  yield handleQueryStoreTaxOptions({
      type: ActionType.QUERY_STORE_TAX_OPTIONS,
      storeSlug,
    });
    const roundingOption: any = forExpressOrder ? undefined : yield handleQueryStoreRoundingOptions({
      type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
      storeSlug,
    });


    const store: IStore | undefined = queryStoreResult.store;

    if (store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);
      if (forExpressOrder) {
        shoppingCartData = cartState.storeIDRefExpressOrderData[choiceResult.storeID];
      } else {
        shoppingCartData = cartState.storeIDRefShoppingCartData[choiceResult.storeID];
      }

      if (shoppingCartData) {
        if (operate === 'ADD') {
          shoppingCartData = OrderBuilder.addLineItemQuantity(choiceResult.storeID, uuid, quantity, taxOptions, roundingOption, disableServiceCharge);
          yield put<Action>({
            type: ActionType.SET_STORE_SHOPPING_CART,
            storeID: choiceResult.storeID,
            shoppingCartData,
            forExpressOrder,
          });
        } else {
          shoppingCartData = OrderBuilder.minusLineItemQuantity(choiceResult.storeID, uuid, quantity, taxOptions, roundingOption, forExpressOrder, disableServiceCharge);
          yield put<Action>({
            type: ActionType.SET_STORE_SHOPPING_CART,
            storeID: choiceResult.storeID,
            shoppingCartData,
            forExpressOrder,
          });
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(shoppingCartData);
  }
}

function* handleChangeShippingMethod(params: IChangeShippingMethod) {
  const { storeSlug, data, disableServiceCharge = false, callback } = params;

  let shoppingCartData: IShoppingCartData | undefined;

  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    const store: IStore | undefined = queryStoreResult.store;

    if (store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);

      let currencyCode = '';
      if (store.groupParentID && store.groupParentID !== store.id) {
        const { store: parentStore } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: store.groupParentID,
        });

        if (parentStore) {
          currencyCode = parentStore.currencyCode;
        }
      } else if (store.groupParentID === store.id) {
        currencyCode = store.currencyCode;
      }

      let taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
        type: ActionType.QUERY_STORE_TAX_OPTIONS,
        storeSlug,
      });
      const roundingOption: any = yield handleQueryStoreRoundingOptions({
        type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
        storeSlug,
      });
      if ((data.shippingMethod !== ShippingMethod.DINE_IN && data.shippingMethod !== ShippingMethod.QRCODE_SHOPPING)
        || (data.shippingMethod === ShippingMethod.DINE_IN && disableServiceCharge)) {
        taxOptions = taxOptions.filter(item => Number(item.taxType) !== 2);
      }

      shoppingCartData = OrderBuilder.changeShippingMethod({
        storeID: choiceResult.storeID,
        isSuperOrder: choiceResult.isSuperOrder,
        disableServiceCharge,
        currencyCode,
        setShippingMethodParams: data,
        taxOptions,
        roundingOption,
      });
      if (shoppingCartData) {
        yield put<Action>({
          type: ActionType.SET_STORE_SHOPPING_CART,
          storeID: choiceResult.storeID,
          shoppingCartData,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(shoppingCartData);
  }
}

function* handleGetStoreShoppingCartData(params: IGetStoreShoppingCartData) {
  const { storeSlug, resetDineInTable, orderNumber, isSplitOrder, callback, data } = params;

  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
  let shoppingCartData: IShoppingCartData | undefined;
  const allShoppingCartData: IShoppingCartData[] = [];
  let storeID = '';
  let store: IStore | undefined = undefined;
  let storeConfig: IStoreConfig | undefined = undefined;
  try {
    const queryStoreResult = yield handleQueryStore({
      type: ActionType.QUERY_STORE,
      storeSlug,
    });

    store = queryStoreResult.store;
    storeConfig = queryStoreResult.storeConfig;
    const showOrder = storeConfig ? storeConfig.showOrder : true;
    if (orderNumber && store && showOrder) {

      if (!isSplitOrder) {
        yield put<Action>({
          type: ActionType.CLEAR_STORES_SHOPPING_CART,
          storeIDs: [store.groupParentID],
        });
      }

      shoppingCartData = yield handleAddToShoppingCartByOrder({
        type: ActionType.ADD_TO_SHOPPING_CART_BY_ORDER,
        storeID: store.groupParentID,
        orderNumber: orderNumber,
        isSplitOrder: isSplitOrder,
      });

      if (shoppingCartData) {
        allShoppingCartData.push(shoppingCartData);
      }
      storeID = store.groupParentID;

    }

    //在合单时, 单独下单, 返回后需要重新合并购物车数据
    if ((!orderNumber || isSplitOrder ) && store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);
      storeID = choiceResult.storeID;

      if (!isSplitOrder) {
        shoppingCartData = cartState.storeIDRefShoppingCartData[storeID];
        if (shoppingCartData) {
          allShoppingCartData.push(...Object.values(cartState.storeIDRefShoppingCartData));
        }
      }

      let currencyCode = '';
      if (store.groupParentID && store.groupParentID !== store.id) {
        const { store: parentStore } = yield handleQueryStoreByID({
          type: ActionType.QUERY_STORE_BY_ID,
          storeID: store.groupParentID,
        });

        if (parentStore) {
          currencyCode = parentStore.currencyCode;
        }
      } else if (store.groupParentID === store.id) {
        currencyCode = store.currencyCode;
      }

      if (!shoppingCartData || isSplitOrder) {
        const localShoppingCart: { [storeID: string]: IOrderEntityJson } = getLocalStorageJson(Constants.SHOPPING_CART_DATA);
        if (isSplitOrder && allShoppingCartData.length > 0) {
          allShoppingCartData.forEach(item => {
            localShoppingCart[item.orderData.store_id] = item.orderData;
          });
        }

        if (localShoppingCart && Object.keys(localShoppingCart).length > 0) {
          const localStoreIDs = Object.keys(localShoppingCart);
          for (let i = 0; i < localStoreIDs.length; i++) {
            const localStoreID = localStoreIDs[i];
            const orderEntityJson = localShoppingCart[localStoreID];
            if (!orderEntityJson) {
              continue;
            }

            let storePickupLocations: any;
            if (localShoppingCart && localShoppingCart[localStoreID] && !localShoppingCart[localStoreID].pickup_location) {
              storePickupLocations = yield handleQueryStorePickupLocations({
                type: ActionType.QUERY_STORE_PICKUP_LOCATIONS,
                storeSlug,
              });
            }

            const storeIDs = OrderBuilder.getStoreIDsByOrderEntityJson(orderEntityJson);
            if (Array.isArray(storeIDs) && storeIDs.length > 0) {
              yield handleQueryStoresListings({
                type: ActionType.QUERY_STORES_LISTINGS,
                storeIDs,
              });
            }

            const { store: realStore } = yield handleQueryStoreByID({
              type: ActionType.QUERY_STORE_BY_ID,
              storeID: orderEntityJson.store_id,
            });

            const taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
              type: ActionType.QUERY_STORE_TAX_OPTIONS,
              storeSlug: realStore.slug,
            });

            const storeIDRefTaxOptions: { [storeID: string]: ITaxOption[] } = {};

            for (let i = 0; i < orderEntityJson.line_items.length; i++) {
              const lineItem = orderEntityJson.line_items[i];
              if (lineItem && lineItem.store_id) {
                const { store: lineItemStore } = yield handleQueryStoreByID({
                  type: ActionType.QUERY_STORE_BY_ID,
                  storeID: lineItem.store_id,
                });
                if (lineItemStore) {
                  const taxOptions: ITaxOption[] = yield handleQueryStoreTaxOptions({
                    type: ActionType.QUERY_STORE_TAX_OPTIONS,
                    storeSlug: lineItemStore.slug,
                  });
                  storeIDRefTaxOptions[lineItemStore.id] = taxOptions;
                }
              }
            }

            let isSuperOrder = false;
            for (let i = 0; i < orderEntityJson.line_items.length; i++) {
              const lineItems = orderEntityJson.line_items[i];
              if (String(lineItems.store_id) !== String(localStoreID)) {
                isSuperOrder = true;
                break;
              }
            }

            const roundingOption: any = yield handleQueryStoreRoundingOptions({
              type: ActionType.QUERY_STORE_ROUNDING_OPTIONS,
              storeSlug: realStore.slug,
            });

            const initData: any = OrderBuilder.initStoreOrderEntity({
              storeID: orderEntityJson.store_id,
              isSuperOrder,
              currencyCode,
              orderEntityJson,
              taxOptions,
              roundingOption,
              storeIDRefTaxOptions,
              storePickupLocations: localShoppingCart && localShoppingCart[localStoreID] && localShoppingCart[localStoreID].pickup_location? [localShoppingCart[localStoreID].pickup_location]: storePickupLocations && storePickupLocations,
              disableServiceCharge: storeConfig && storeConfig.disableServiceCharge ? true : false,
            });

            if (initData) {
              allShoppingCartData.push(initData);
              if (resetDineInTable && initData.orderInfo && initData.orderInfo.shippingMethod === ShippingMethod.DINE_IN) {
                if (data) {
                  yield handleChangeShippingMethod({
                    type: ActionType.CHANGE_SHIPPING_METHOD,
                    storeSlug,
                    data,
                  });
                } else {
                  initData.orderData.party = null;
                  initData.orderInfo.dineInParty = null;
                  yield handleChangeShippingMethod({
                    type: ActionType.CHANGE_SHIPPING_METHOD,
                    storeSlug,
                    data: {
                      shippingMethod: ShippingMethod.DINE_IN,
                    }
                  });
                }
              } else if (data && data.shippingMethod === ShippingMethod.DINE_IN && data.dineInParty) {
                initData.orderData.party['table_id'] = data.dineInParty?.tableID;
                initData.orderData.party['table_name'] = data.dineInParty?.tableName;
                initData.orderInfo.dineInParty = data.dineInParty;
              }
              shoppingCartData = initData;

              yield put<Action>({
                type: ActionType.SET_STORE_SHOPPING_CART,
                storeID: orderEntityJson.store_id,
                shoppingCartData: initData,
              });
            }
          }
        }
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback({
      storeID,
      shoppingCartData,
      store,
      storeConfig,
      allShoppingCartData,
    });
  }
}

function* handleSetStoreShoppingCartInfo(params: ISetStoreShoppingCartNote) {
  const { notes, dates, callback } = params;
  const NoteKeys = Object.keys(notes);
  const DateKeys = Object.keys(dates);
  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
  const shoppingCartData = cloneDeep(cartState.storeIDRefShoppingCartData);
  try {
    NoteKeys.forEach(item => {
      if (cartState.storeIDRefShoppingCartData[item]) {
        shoppingCartData[item].orderData.note = notes[item];
        shoppingCartData[item].orderInfo.note = notes[item];
        if (!shoppingCartData[item].orderInfo.superOrder && shoppingCartData[item].orderInfo.orderEntities[0]) {
          shoppingCartData[item].orderInfo.orderEntities[0].note = notes[item];
        }
      }
    })
    DateKeys.forEach(item => {
      if (cartState.storeIDRefShoppingCartData[item]) {
        shoppingCartData[item].orderData['delivery_date'] = dates[item].toISOString();
      }
    })
    OrderBuilder.setOrderNoteAndDeliveryDate(notes, dates);
    yield put<Action>({
      type: ActionType.SET_SHOPPING_CART,
      shoppingCartData,
    });
  } catch (error) {
    console.error(error);
  }
  if (callback) {
    callback();
  }
}

function* handleGetExpressOrderData(params: IGetExpressOrderData) {
  const { callback } = params;
  const storeIDRefExpressOrderData: {
    [storeID: string]: IShoppingCartData;
  } = {};
  let store: IStore | undefined = undefined;

  try {
    const favoriteListingInfo: IFavoriteListingInfo = yield handleQueryFavoriteListings({
      type: ActionType.QUERY_FAVORITE_LISTINGS,
    });

    OrderBuilder.clearOrderEntities();

    store = favoriteListingInfo.platformStore;

    let currencyCode = '';
    if (store && store.groupParentID && store.groupParentID !== store.id) {
      const { store: parentStore } = yield handleQueryStoreByID({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID: store.groupParentID,
      });

      if (parentStore) {
        currencyCode = parentStore.currencyCode;
      }
    } else if (store && store.groupParentID === store.id) {
      currencyCode = store.currencyCode;
    }

    const localShoppingCart: { [storeID: string]: IOrderEntityJson } = getLocalStorageJson(Constants.SHOPPING_CART_DATA);

    const state: IReducersState = yield select((state: IReducersState) => state);
    const { slugRefTaxOptions, slugRefRoundingOptions }  = state.order;
    favoriteListingInfo.favoriteListings.forEach(item => {
      const favoriteListing = item;
      let quantity = 0;
      if (localShoppingCart && Object.keys(localShoppingCart).length > 0) {
        const orderEntityJson = localShoppingCart[favoriteListing.storeID] || {};
        orderEntityJson.line_items && orderEntityJson.line_items.forEach(item => {
          if (String(favoriteListing.favoriteID) === String(item.favorite_id)) {
            quantity = item.quantity;
          }
        })
      }
      const result = OrderBuilder.addToCartForPaths({
        storeID: favoriteListing.storeID,
        isSuperOrder: false,
        listingStoreID: favoriteListing.storeID,
        currencyCode,
        taxOptions: slugRefTaxOptions[favoriteListing.storeSlug],
        quantity,
        shippingMethod: ShippingMethod.DELIVERY,
        roundingOption: slugRefRoundingOptions[favoriteListing.storeSlug],
        paths: {
          listingID: favoriteListing.favoriteID,
        },
      });

      if (result) {
        storeIDRefExpressOrderData[favoriteListing.storeID] = result;
      }
    })

  } catch (error) {
    console.error(error);
  }

  const expressOrders: IShoppingCartData[] = Object.values(storeIDRefExpressOrderData);

  if (callback) {
    callback(expressOrders);
  }

  yield put<Action>({
    type: ActionType.SET_EXPRESS_ORDER_DATA,
    storeIDRefExpressOrderData,
  });

  return expressOrders;
}

export function* handleSetExpressOrderForZero(params: ISetExpressOrderForZero) {
  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
  const { storeID = '' }  = params;
  const shoppingCartData = OrderBuilder.zeroLineItemsQuantity(storeID);
  let storeIDRefExpressOrderData = cloneDeep(cartState.storeIDRefExpressOrderData)
  if (shoppingCartData) {
    if (storeID) {
      storeIDRefExpressOrderData[storeID] = shoppingCartData;
    } else {
      storeIDRefExpressOrderData = shoppingCartData;
    }
    yield put<Action>({
      type: ActionType.SET_EXPRESS_ORDER_DATA,
      storeIDRefExpressOrderData,
    });
  }
  return shoppingCartData;
}

function* handleSetDiscountValue(params: ISetDiscountValue) {
  const { storeID, storeSlug, forExpressOrder = false, callback } = params;
  const cartState: IShoppingCartState = yield select((state: IReducersState) => state.cart);
  let shoppingCartData: IShoppingCartData | undefined;
  let queryStoreResult;

  try {
    if (storeID) {
      queryStoreResult = yield handleQueryStoreByID({
        type: ActionType.QUERY_STORE_BY_ID,
        storeID,
      });
    } else {
      queryStoreResult = yield handleQueryStore({
        type: ActionType.QUERY_STORE,
        storeSlug,
      });
    }

    const store: IStore | undefined = queryStoreResult.store;

    if (store) {
      const choiceResult = choiceStoreID(store.id, store.groupParentID);
      if (forExpressOrder) {
        shoppingCartData = cartState.storeIDRefExpressOrderData[choiceResult.storeID];
      } else {
        shoppingCartData = cartState.storeIDRefShoppingCartData[choiceResult.storeID];
      }

      if (shoppingCartData) {
        shoppingCartData = OrderBuilder.changeOrderEntity(params);
        yield put<Action>({
          type: ActionType.SET_STORE_SHOPPING_CART,
          storeID: choiceResult.storeID,
          shoppingCartData,
          forExpressOrder,
        });
      }
    }
  } catch (error) {
    console.error(error);
  }

  if (callback) {
    callback(shoppingCartData);
  }
}

function* paySaga() {
  yield takeEvery(ActionType.ADD_TO_SHOPPING_CART_BY_ORDER, handleAddToShoppingCartByOrder);
  yield takeEvery(ActionType.PUSH_LISTING_TO_SHOPPING_CART, handlePushListingToShoppingCart);
  yield takeEvery(ActionType.MODIFY_LINE_ITEM_QUANTITY, handleModifyLineItemQuantity);
  yield takeEvery(ActionType.CHANGE_SHIPPING_METHOD, handleChangeShippingMethod);
  yield takeEvery(ActionType.GET_STORE_SHOPPING_CART_DATA, handleGetStoreShoppingCartData);
  yield takeEvery(ActionType.SET_STORE_SHOPPING_CART_INFO, handleSetStoreShoppingCartInfo);
  yield takeEvery(ActionType.GET_EXPRESS_ORDER_DATA, handleGetExpressOrderData);
  yield takeEvery(ActionType.SET_EXPRESS_ORDER_FOR_ZERO, handleSetExpressOrderForZero);
  yield takeEvery(ActionType.SET_DISCOUNT_VALUE, handleSetDiscountValue);
}

export default paySaga();
