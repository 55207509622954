import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import AntdIcon from 'antd-mobile/es/icon';
import Button from 'antd-mobile/es/button';
import Modal from 'antd-mobile/es/modal';
import { IObject } from '@/components/bm-common';
import { parseRouteParams, createRouteUrl, getDomain, getPlatformPayInfo } from '@/utils';
import ActionType from '@/actions/action-type';
import paths from '@/routes/paths';
// import DiningStyle from '@/components/DiningStyle';
import PayInfo from '@/components/PayInfo';
import { setScreenColor, getAppTheme, getIntl, getIntlByPath } from '@/components/App/App';
import { formatCurrency } from '@/components/Payment';
import ListingsPanel from '@/components/ListingsPanel';
import { usePayment, PayTypes, IPayMethod, useCheckPay as UseCheckPay, IWindowGoogle } from '@/lib/payment';
import Icon, { IconType } from '@/components/Icon';
// import Layout from '@/components/Layout';
import OrderEntity, { IOrderInfo, ISaleTransaction } from '@/lib/order-builder/models/OrderEntity';
import { IStore, IStoreConfig } from '@/actions/store-action';
import Skeleton from './Skeleton';
import { debounce } from '@/utils/debounce-util';
import Constants from '@/constants';
import { getDeviceInfo, getI18nText, langFormat } from '@/utils/app';
// import { TransitionKeys } from '@/components/Transition';
import PayMethodPage from '../PayMethodPage/PayMethodPage';
import PayCardPage from '../PayCardPage/PayCardPage';
import Layout from '@/components/Layout';
import { PrivilegePageStyled } from '@/pages/member-page/PrivilegePage/styled';
import InputItem from 'antd-mobile/es/input-item';
import cloneDeep from 'lodash/cloneDeep';
import Config, { isStaging } from '@/Config';
import Script from 'react-load-script';

export interface IConfirmOrderPageProps extends IBasePageProps {}

export interface IConfirmOrderPageState {
  orderInfo?: IOrderInfo;
  store?: IStore;
  paying: boolean;
  checking: boolean;
  showTermsPopup: boolean;
  loadingOrerInfo: boolean;
  isShow?: boolean;
  showPayCardModal: boolean;
  privacyPolicyModal: boolean;
  showCVVPopup: boolean;
  cvv: string;
  paymentData?: IObject | undefined;
  buyerStoreID?: string | undefined;
  showGooglePay?: boolean;
  merchantInfo: {
    tag: string;
    value: string;
  };
}

// const shippingMethodKV: any = {
//   sm0: 'takeAway',
//   sm1: 'delivery',
//   sm2: 'dineIn',
//   sm3: 'dineIn',
// }

class ConfirmOrderPage extends BasePage<IConfirmOrderPageProps, IConfirmOrderPageState> {
  routeParams: IObject = {};

  state: IConfirmOrderPageState = {
    orderInfo: undefined,
    store: undefined,
    paying: false,
    checking: true,
    showTermsPopup: false,
    loadingOrerInfo: true,
    isShow: false,
    showPayCardModal: false,
    privacyPolicyModal: false,
    showCVVPopup: false,
    cvv: '',
    paymentData: undefined,
    buyerStoreID: undefined,
    showGooglePay: false,
    merchantInfo: {
      tag: '',
      value: '',
    }
  }

  paymentsClient: any = null;

  componentDidMount() {
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }

    super.componentDidMount();

    this.routeParams = parseRouteParams(this.props);
    if (this.routeParams.check === '1') {
      this.setState({ paying: true, checking: true });
    } else {
      this.setState({ paying: false, checking: false });
    }
    const { dispatch, reducersState } = this.props;
    const { fromUrlPayData } = reducersState.pay;
    const { payInfo, code, woa } = this.routeParams;
    let payStoreID; // 下单时真正的storeID

    if (payInfo && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfoObj = getPlatformPayInfo(payInfo);
      if (payInfoObj.lang) {
        dispatch({ type: ActionType.SET_LANGUAGE, language: payInfoObj.lang });
      }
      this.setState({
        paymentData: payInfoObj.paymentData || undefined,
        buyerStoreID: payInfoObj.buyerStoreID,
      });
      payStoreID = payInfoObj.storeID;
    }

    if (this.routeParams.orderNumber && this.routeParams.storeSlug) {
      dispatch({
        type: ActionType.QUERY_ORDER_INFO,
        storeSlug: this.routeParams.storeSlug,
        orderNumber: this.routeParams.orderNumber,
        fromUrlPayData,
        storeID: payStoreID,
        callback: (orderInfo, store, storeConfig, merchantInfo) => {
          this.setState({ orderInfo, store, loadingOrerInfo: false });
          if (this.routeParams.check === '1' && store) {
            this.checkPayResult(store.id, this.routeParams.orderNumber);
          }
          if (merchantInfo && merchantInfo.tag) {
            this.setState({ merchantInfo });
          }
        }
      });
      const deviceInfo = getDeviceInfo();
      if ((!code || woa !== '1') && deviceInfo.isWeChat && !deviceInfo.weChatMiniProgram) {
        const { origin, pathname } = window.location
        const url = `${origin}${pathname}`
        window.location.href = `https://pay.wonder.app/pay/code?aid=wx07d4a1b7a7e53b69${isStaging ? '&env=stg' : ''}&redirect_uri=${encodeURIComponent(url)}`;
      }
    } else {
      this.setState({ loadingOrerInfo: false });
    }
  }

  checkPayResult = async (storeID: string, orderNumber: string) => {
    await UseCheckPay({
      storeID,
      orderNumber,
      callback: this.checkPayCallback,
    });
  }

  gotoPayResultsPage = (order: any) => {
    const { dispatch } = this.props;
    const { buyerStoreID } = this.state;

    dispatch({
      type: ActionType.SET_ORDER,
      orderNumber: order.number,
      order,
    });

    const query: any = {};
    if (this.routeParams.token) {
      query.token = this.routeParams.token;
    }
    if (this.routeParams.domain) {
      query.domain = this.routeParams.domain;
    }
    if (buyerStoreID) {
      query.buyerStoreID = buyerStoreID;
    }

    this.handleGotoRoute({
      path: paths.PAY_RESULTS,
      pathParams: {
        storeSlug: this.routeParams.storeSlug,
        orderNumber: this.routeParams.orderNumber,
      },
      historyState: query,
    });
  }

  checkPayCallback = (data: any) => {
    const { order, status } = data || {};

    if (order && order.state === 'completed') {
      this.gotoPayResultsPage(order);
    }

    this.setState({ paying: false });
    if ( status === 424 ) { // 支付失败, 重新支付
      this.setState({ checking: false });
    }
  }

  handlePay = async () => {
    const { reducersState } = this.props;
    const { orderInfo, store, merchantInfo } = this.state;
    
    if (!orderInfo || !store) return;

    let storeConfig: IStoreConfig | undefined;

    const { payMethods } = reducersState.pay;
    const { isWeChat, weChatMiniProgram } = reducersState.app.deviceInfo

    const {
      safetyVerification: getawapSafetyVerification = false,
      googlePayMethodKeys = [],
    } = reducersState.app.gatewayAppSettings || {};

    const {
      safetyVerification: appSafetyVerification = false,
      safetyVerificationAmount: appSafetyVerificationAmount,
    } = reducersState.app.platformAppSettings || {};

    const storeConfigRes = await this.getStoreConfig(orderInfo.storeSlug);

    if (storeConfigRes) {
      storeConfig = storeConfigRes;
    }

    const {
      safetyVerification,
      safetyVerificationAmount,
    } = storeConfig || {};


    this.setState({ paying: true });

    const newPayMethods: IPayMethod[] = JSON.parse(JSON.stringify(payMethods));
    const { payInfo = '' } = this.routeParams;
    let returnUrl = '';
    
    /** TODO 月付功能，暂时不支持，直接跳转到成功页面 */
    // const includingDeferredBilling = newPayMethods.some(item => item.id === PayTypes.DEFERRED_BILLING);
    // if (includingDeferredBilling) {
    //   this.gotoPayResultsPage({});
    //   return;
    // }

    for (let i = 0; i < newPayMethods.length; i++) {
      const item = newPayMethods[i];
      if (item.type === PayTypes.ALIPAY || item.type === PayTypes.ALIPAY_HK || item.type === PayTypes.TAP_GO || item.type === PayTypes.CREDIT_CARD || item.type === PayTypes.WECHAT_PAY) {
        returnUrl = window.location.origin + createRouteUrl(
          paths.ORDER_CONFIRM,
          {
            payInfo,
            query: {
              check: 1,
            }
          },
          this.props,
        );

        item.returnUrl = returnUrl;
      }

      // 会员用户与游客
      if (item.type === PayTypes.CREDIT_CARD || item.type === PayTypes.VISA || item.type === PayTypes.MASTER || item.type === PayTypes.AMEX || item.type === PayTypes.UNION_PAY) {
        returnUrl = window.location.origin + createRouteUrl(
          paths.ORDER_CONFIRM,
          {
            payInfo,
            query: {
              check: 1,
            }
          },
          this.props,
        );

        if (item.creditCardType === PayTypes.UNION_PAY || item.type === PayTypes.UNION_PAY) {
          returnUrl = `${Config.bindoHq}/redirect?url=${returnUrl}`;
        }

        item.returnUrl = returnUrl;
        item.getawapSafetyVerification = getawapSafetyVerification;
        item.appSafetyVerification = appSafetyVerification;
        item.appSafetyVerificationAmount = appSafetyVerificationAmount;
        item.safetyVerification = safetyVerification;
        item.safetyVerificationAmount = safetyVerificationAmount;
      }

      if (item.type === PayTypes.APPLE_PAY) {
        item.deviceInfo = reducersState.app.deviceInfo;
      }
      
      if (item.type === PayTypes.WECHAT_PAY && isWeChat && !weChatMiniProgram) {
        const { code, woa } = parseRouteParams(this.props);
        if (code && woa === '1' ) {
          item.code = code;
          item.appid = 'wx07d4a1b7a7e53b69';
        }
      }

      if (item.type === PayTypes.GOOGLE_PAY) {
        const paymentRequest = {
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: googlePayMethodKeys,
                allowPrepaidCards: true,
                assuranceDetailsRequired: true,
              },
              tokenizationSpecification: {
                type: 'DIRECT',
                parameters: {
                  protocolVersion: 'ECv2',
                  publicKey: merchantInfo.value,
                },
              },
            },
          ],
          merchantInfo: {
            merchantId: merchantInfo.tag,
            merchantName: 'Wonder',
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: item.amount.toString(),
            currencyCode: item.currencyCode,
            countryCode: store.countryCode,
          },
        }
    
        const paymentsClient = this.getGooglePaymentsClient();
        paymentsClient.prefetchPaymentData(paymentRequest);
        const data = await paymentsClient.loadPaymentData(paymentRequest).catch(() => {
          this.setState({ paying: false });
        });
        if (data && data.paymentMethodData) {
          item.googlePayData = {
            tag: merchantInfo.tag,
            token: data,
          }
        } else {
          return;
        }
      }
    }

    const { runPayOrder } = usePayment({
      orderNumber: orderInfo.orderNumber,
      storeID: store.id,
      payData: newPayMethods,
      extraAction: {
        onCancel: () => {
          this.setState({ paying: false });
        }
      }
    });

    const result = await runPayOrder();
    const {
      order,
      status,
      code,
      message,
    } = result || {};

    this.setState({ paying: false });

    if (order && (order.state === 'completed' || (order.state === 'invoiced' && order.sale_type === 'purchase_request'))) {
      this.gotoPayResultsPage(order);
    } else if (code && code === '100400' && message === 'Payment:The amount cannot be less than 0.2') { // 支付失败
      Modal.alert(getIntl().common.tips, <>{getIntl().common.payErrorTips}<div>{`[${code}]`}</div></>, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      this.setState({ paying: false });
    } else if (status === 500) { // 支付失败
      Modal.alert(getIntl().common.tips, <>{getIntl().common.payfail}<div>{code && `[${code}]`}</div></>, [
        {
          text: getIntl().common.confirm,
          onPress: () => {},
        }
      ]);
      this.setState({ paying: false });
    } else if (status === 400) { // apple pay 取消支付
      this.setState({ paying: false });
    }
  }

  handleItemClick = () => {
    const query: any = {};
    if (this.routeParams.token) {
      query.token = this.routeParams.token;
    }
    if (this.routeParams.domain) {
      query.domain = this.routeParams.domain;
    }
    this.setState({isShow: true});

    // this.handleGotoRoute({
    //   path: paths.ORDER_PAY_METHOD,
    //   historyState: query,
    //   animationType: TransitionKeys.FADE_FROM_BOTTOM,
    // });
  }

  handleDeleteClick = () => {
    const { dispatch, reducersState} = this.props;
    const { orderInfo } = this.state;
    let { payMethods } = reducersState.pay;
    payMethods = payMethods.filter(_item => _item.type !== PayTypes.ANONYMOUS_GIFT_CARD);
    let payMethod = null;
    if (orderInfo) {
      for (let i = 0; i < payMethods.length; i++) {
        const tempPayMethod = payMethods[i];
        payMethod = JSON.parse(JSON.stringify(tempPayMethod));
      }
      if (payMethod) {
        payMethod.amount = orderInfo.initialTotal;
        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [payMethod],
        });
      } else {
        dispatch({
          type: ActionType.MODIFY_PAY_METHODS,
          payMethods: [],
        });
      }
    }
  }
  getPayMethod = (method: PayTypes) => {
    const paymentMethodRef: IObject = {
      [PayTypes.CASH_ON_DELIVERY]: 1,
      // [IconType.OE_PAY]: 3,
      // [IconType.CREDIT_CARD]: 4,
      // [IconType.VISA_CARD]: 4,
      // [IconType.MASTER_CARD]: 4,
      [PayTypes.UNION_PAY]: 4,
      [PayTypes.AMEX]: 4,
      [PayTypes.MASTER]: 4,
      [PayTypes.VISA]: 4,
      [PayTypes.CREDIT_CARD]: 4,
      [PayTypes.ANONYMOUS_GIFT_CARD]: 16,
      [PayTypes.STORE_CREDIT]: 16,
      [PayTypes.WECHAT_PAY]: 17,
      [PayTypes.APPLE_PAY]: 18,
      [PayTypes.ALIPAY]: 19,
      [PayTypes.ALIPAY_HK]: 19,
      [PayTypes.TAP_GO]: 21,
      [PayTypes.DEFERRED_BILLING]: 22,
      [PayTypes.GOOGLE_PAY]: 23,
    }

    const payMethod: number = paymentMethodRef[method];

    return payMethod;
  }

  getSaleTransactions = () => {
    const { reducersState } = this.props;
    const { orderInfo, checking } = this.state;

    let saleTransactions: ISaleTransaction[] = [];
    if (checking) {
      if (orderInfo) {
        saleTransactions = orderInfo.saleTransactions.filter(item => item.success !== false);
      }
    } else {
      const { payMethods } = reducersState.pay;
      payMethods.forEach(item => {
        saleTransactions.push({
          id: item.id || item.type,
          amount: item.amount as number,
          currencyCode: item.currencyCode,
          // paymentMethod: payKeyRefMethod[],
          paymentMethod: this.getPayMethod(item.type),
          creditCardType: item.creditCardType || item.type || '',
          last4Digits: item.last4Digits || '',
          balance: item.balance || 0,
        });
      })
    }
    return saleTransactions;
  }

  handleLeftClick = () => {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { paymentData } = this.state;

    const { platformHost, isLocalhost } = getDomain(platformAppSettings && platformAppSettings.domain);
    const protocol = `http${isLocalhost ? '' : 's'}:`;

    const { storeSlug, orderNumber, payInfo } = this.routeParams;

    let { token, fromPage } = this.routeParams;
    

    if (!token && payInfo && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfoObj = getPlatformPayInfo(payInfo);
      token = payInfoObj.token;
      const fromPageData = payInfo.split('&')[1];
      if (fromPageData) {
        fromPage = fromPageData.split('=')[1];
      }
    }

    if (!storeSlug) {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.HOME,
        {},
      )}`
    } else if (reducersState.user.isGuest) {
      if (fromPage === 'eShop') {
        window.location.href = `${protocol}//${platformHost}${createRouteUrl(
          paths.CHECKOUT_BY_FROM,
          {
            storeSlug,
            fromPage: 'eShop',
            query: {
              orderNumber,
              token,
            },
          },
        )}`
      } else {
        window.location.href = `${protocol}//${platformHost}${createRouteUrl(
          paths.CHECKOUT,
          {
            storeSlug,
            payInfo,
            query: {
              orderNumber,
              token,
            },
          },
        )}`
      }
    } else if (fromPage === 'eShop') {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.SHOPPING_CART_BY_FROM,
        {
          storeSlug,
          fromPage: 'eShop',
          query: {
            orderNumber,
            token,
          },
        },
      )}`
    } else if (fromPage === 'forExpressOrder') {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.EXPRESS_ORDER,
        {
          storeSlug,
          payInfo,
          query: {
            orderNumber,
          },
        }
      )}`
    } else if (paymentData && paymentData.deferredOrder) {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.DEFERRED_INVOICES,
        {
          storeSlug: paymentData.selectStoreSlug,
        }
      )}`
    }
    else {
      window.location.href = `${protocol}//${platformHost}${createRouteUrl(
        paths.SHOPPING_CART,
        {
          storeSlug,
          payInfo,
          query: {
            orderNumber,
            token,
          },
        },
      )}`
    }
  }

  getOrderItemCount = (orderEntities: OrderEntity[]) => {
    let count = 0;

    orderEntities.forEach(orderEntity => {
      orderEntity.lineItems.forEach(item => {
        count += item.quantity;
      });
    });

    return count;
  }
  getSaleTransactionsAmount = () => {
    let amount = 0;
    const saleTransactions = this.getSaleTransactions();
    saleTransactions.forEach(item => {
      amount += item.amount;
    })
    return amount;
  }

  getStoreConfig = async (storeSlug: string): Promise<IStoreConfig> => {
    const { dispatch } = this.props;

    return new Promise(resolve => {
      dispatch({
        type: ActionType.QUERY_STORE,
        storeSlug,
        callback: (store, queryStoreConfig) => {
          if (queryStoreConfig) {
            resolve(queryStoreConfig);
          }
        }
      });
    });
  }

  handleEnterCVVandPay = () => {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;
    const { payMethods } = reducersState.pay;
    const paymentTypes: PayTypes[] = [];
    payMethods.forEach(item => {
      paymentTypes.push(item.type);
    });
    if (platformAppSettings && platformAppSettings.enableCvvAuthentication && (paymentTypes.includes(PayTypes.CREDIT_CARD) || paymentTypes.includes(PayTypes.VISA) || paymentTypes.includes(PayTypes.MASTER) || paymentTypes.includes(PayTypes.AMEX) || paymentTypes.includes(PayTypes.UNION_PAY))) {
      this.setState({
        showCVVPopup: true,
      })
    } else {
      this.handlePay();
    }
  }

  handleSetCVV = () => {
    const { dispatch, reducersState } = this.props;
    const { orderInfo, cvv } = this.state;
    const { payMethods } = reducersState.pay;
    let payMethod: IPayMethod | undefined = undefined;
    payMethods.forEach((item: IPayMethod) => {
      if ((item.type === PayTypes.CREDIT_CARD || item.type === PayTypes.VISA || item.type === PayTypes.MASTER || item.type === PayTypes.AMEX || item.type === PayTypes.UNION_PAY) && orderInfo) {
        payMethod = cloneDeep(item);
        payMethod['cvv']= cvv || '';
      }
    });
    if (payMethod && orderInfo) {
      dispatch({
        type: ActionType.MODIFY_PAY_METHODS,
        payMethods: [payMethod],
      });
    }
    setTimeout(() => {
      this.handlePay();
    }, 300);

  }

  getGooglePaymentsClient = () => {
    const { google } = window as IWindowGoogle;
    if (this.paymentsClient === null && google) {
      this.paymentsClient = new google.payments.api.PaymentsClient({
        environment: isStaging ? 'TEST' : 'PRODUCTION',
      });
    }
    return this.paymentsClient;
  };

  googlePayShowOrHidden = () => {
    const { reducersState } = this.props;
    const { gatewayAppSettings } = reducersState.app;
    const { googlePayMethodKeys = [] } = gatewayAppSettings || {}
    const paymentsClient = this.getGooglePaymentsClient();
    if (paymentsClient) {
      paymentsClient
        .isReadyToPay(
          Object.assign({}, {
            apiVersion: 2,
            apiVersionMinor: 0,
          }, {
            allowedPaymentMethods: [
              {
                type: 'CARD',
                parameters: {
                  allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                  allowedCardNetworks: googlePayMethodKeys,
                },
              }
            ]
          })
        )
        .then((response: any) => {
          if (response.result) {
            this.setState({ showGooglePay: true });
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  render() {
    const { reducersState } = this.props;
    const { platformAppSettings } = reducersState.app;

    const { orderInfo, paying, checking, loadingOrerInfo, isShow, showPayCardModal, showTermsPopup, privacyPolicyModal, showCVVPopup, paymentData, showGooglePay } = this.state;
    const saleTransactions = this.getSaleTransactions();
    const transactionsAmount = this.getSaleTransactionsAmount();
    const initialTotal = orderInfo ? orderInfo.initialTotal : 0;
    
    return (
      <Layout
        {...this.props}
        className={`${prefix} ${loadingOrerInfo ? 'loading-order-info' : ''}`}
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        onLeftClick={this.handleLeftClick}
        navContent={!loadingOrerInfo && getIntl().page.selectPayTitle}
        isFooter={false}
      >
        <Script url="https://pay.google.com/gp/p/js/pay.js" onLoad={this.googlePayShowOrHidden} />
        {
          loadingOrerInfo &&
          <Skeleton />
        }
        <div className="background-top"></div>
        {
          !loadingOrerInfo &&
          <div className="section no-padding">
            <div className="container top w-container">
              <div className="div-block-485">
                <div className="left-block">
                  {
                    orderInfo && orderInfo.orderEntities.map(item => (
                      <div key={item.storeID}>
                        <ListingsPanel
                          key={item.id}
                          data={item}
                          showStoreTitle={true}
                          showRightTopCount={true}
                          className="store-listings-panel"
                          reducersState={reducersState}
                          orderInfo={orderInfo}
                          showChange={false}
                          showTotal={true}
                          confirmPage={true}
                        />
                      </div>
                    ))
                  }
                </div>
                <div className="right-block">
                  <div className="check-out">
                    <div className="payment-info-block">
                      <PayInfo
                        title={getIntl().common.payment}
                        className="content-panel"
                        orderInfo={orderInfo}
                        showItemLeftIcon={true}
                        showBalance={true}
                        saleTransactions={saleTransactions}
                        onItemClick={this.handleItemClick}
                        onDeleteClick={this.handleDeleteClick}
                        onAddPayMethod={checking ? undefined : this.handleItemClick}
                      />
                    </div>
                    {
                      !loadingOrerInfo && orderInfo &&
                      <div className="div-block-510">
                        <div className="text-block-90 less-spacing">
                          {getIntl().common.total}: {formatCurrency(orderInfo.initialTotal, orderInfo.currencyCode)}
                        </div>
                        <div className="text-block-91">
                          {langFormat(getIntlByPath(`page.${this.getOrderItemCount(orderInfo.orderEntities) <= 1 ?'nowItemsCounts': 'itemsCount'}`), { count: this.getOrderItemCount(orderInfo.orderEntities) })}
                        </div>
                        <Button className="pay-now-block w-inline-block"  type="primary" disabled={saleTransactions.length < 1 || transactionsAmount < initialTotal || !orderInfo || !['invoiced', 'unpaid', 'async_pending', 'accepted'].includes(orderInfo.state)}
                          onClick={() => {
                            debounce(() => {
                              this.handleEnterCVVandPay()
                            }, 0)
                          }}
                        >
                          <div className="text-block-17"> {getIntl().page.confirmAndPay}</div>
                        </Button>
                        <div className="text-block-210 pc">
                          {getIntl().page.termsAndConditionPre}
                          <strong className="bold-text-3" 
                            onClick={() => this.setState({privacyPolicyModal: true})}
                          >
                            &nbsp;
                            {getIntl().page.privacyPolicy}
                          </strong>
                          <span> & </span>
                          <strong className="bold-text-3" onClick={() => this.setState({showTermsPopup: true})}>
                            {getIntl().page.termsAndCondition}
                          </strong>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="text-block-210">
              {getIntl().page.termsAndConditionPre}
              <strong className="bold-text-3" 
                onClick={() => this.setState({privacyPolicyModal: true})}
              >
                &nbsp;
                {getIntl().page.privacyPolicy}
              </strong>
              <span> & </span>
              <strong className="bold-text-3" onClick={() => this.setState({showTermsPopup: true})}>
                {getIntl().page.termsAndCondition}
              </strong>
            </div>
          </div>
        }
        {
          showTermsPopup &&
          <PrivilegePageStyled>
            <div className="modal-wrapper"> 
              <div className="modal-body coupon">
                <div className="title">
                  <Icon
                    type={IconType.LEFT_OUTLINED}
                    className="html-embed-7 w-embed"
                    onClick={() => this.setState({ showTermsPopup: false })}
                  />
                  <div className="title-tilte">
                    {getIntl().page.terms}
                  </div>
                </div>
                <div className="div-block-549" dangerouslySetInnerHTML={{
                  __html: getI18nText(platformAppSettings, 'general_conditions', reducersState.app.language) || (platformAppSettings && platformAppSettings.generalConditions) || '',
                }}/> 
                <button onClick={() => this.setState({showTermsPopup: false})}>{getIntl().page.readAgree}</button>
              </div>
              <div className="c-popup-close close-modal" onClick={() => this.setState({ showTermsPopup: false })}></div>
            </div>
          </PrivilegePageStyled>
        }
        {
          privacyPolicyModal &&
          <PrivilegePageStyled>
            <div className="modal-wrapper">
              <div className="modal-body coupon">
                <div className="title">
                  <Icon
                    type={IconType.LEFT_OUTLINED}
                    className="html-embed-7 w-embed"
                    onClick={() => this.setState({ privacyPolicyModal: false })}
                  />
                  <div className="title-tilte">
                    {getIntl().page.privacy}
                  </div>
                </div>
                <div className="div-block-549" dangerouslySetInnerHTML={{
                  __html: getI18nText(platformAppSettings, 'privacy_statement', reducersState.app.language) || (platformAppSettings && platformAppSettings.generalConditions) || '',
                }}/> 
                <button onClick={() => this.setState({ privacyPolicyModal: false })}>{getIntl().page.readAgree}</button>
              </div>
              <div className="c-popup-close close-modal" onClick={() => this.setState({ privacyPolicyModal: false })}></div>
            </div>
          </PrivilegePageStyled>
        }
        {
          paying &&
          <div className="co-checking-wrapper">
            <div className="co-checking">
              <div className="co-checking-loading">
                <AntdIcon type="loading" />
              </div>
              <div className="co-checking-text">
                {getIntl().page.paying}
              </div>
            </div>
          </div>
        }
        {
          !loadingOrerInfo && orderInfo &&
          <div className="make-payment">
            <div className="bottom-nav cart-process">
              <div className="container bottom w-container">
                <div className="checkout-tablet">
                  <div>
                    <div className="text-block-90 less-spacing">{getIntl().common.total}: {formatCurrency(orderInfo.initialTotal, orderInfo.currencyCode)}</div>
                    <div className="text-block-91">
                      {langFormat(getIntlByPath(`page.${this.getOrderItemCount(orderInfo.orderEntities) <= 1 ?'nowItemsCounts': 'itemsCount'}`), { count: this.getOrderItemCount(orderInfo.orderEntities) })}
                    </div>
                  </div>
                  <Button className="pay-now-block"  type="primary" disabled={saleTransactions.length < 1 || transactionsAmount < initialTotal || !orderInfo || !['invoiced', 'unpaid', 'async_pending', 'accepted'].includes(orderInfo.state)}
                    onClick={() => {
                      debounce(() => {
                        this.handleEnterCVVandPay()
                      }, 0)
                    }}
                  >
                    <div className="text-block-17"> {getIntl().page.confirmAndPay}</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }
        {
          isShow &&
          <PayMethodPage 
            orderNumber={this.routeParams.orderNumber}
            storeSlug={this.routeParams.storeSlug}
            handleCloseClick={() => this.setState({ isShow: false })}
            onAddcardedClick={() => this.setState({ isShow: false, showPayCardModal: true })}
            deferredOrder={paymentData && paymentData.deferredOrder ? true : false }
            showGooglePay={showGooglePay}
            {...this.props}
          />
        }
        {  
          showPayCardModal && !isShow &&
          <PayCardPage
            {...this.props}
            onCancel={() => this.setState({ isShow: false, showPayCardModal: false })}
          />
        }
        {
          showCVVPopup &&
          <div className="enter-cvv-wrapper" onClick={() => this.setState({ showCVVPopup: false })}>
            <div className="enter-cvv-content">
              <div className="enter-cvv-title">{getIntl().page.enterCvv}</div>
              <div className="cvv">
                <InputItem
                  type="bankCard"
                  value={this.state.cvv}
                  name="cvv"
                  maxLength={4}
                  onChange={value => this.setState({cvv: value})}
                />
              </div>
              <Button className="pay-now-block"  type="primary" disabled={!this.state.cvv} onClick={this.handleSetCVV}>
                <div className="text-block-17">{getIntl().page.confirmAndPay}</div>
              </Button>
            </div>
          </div>
        }
      </Layout>
    );
  }
}

export default ConfirmOrderPage;
