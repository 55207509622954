import { createUuid, plus, times } from '../utils';
import { IModifier } from '../utils/api';
import Context from '../Context';
import LineItemEntity from './LineItemEntity';
import { IObject } from '@/components/bm-common';

export interface IModifierJson {
  uuid: string;
  modifier_set_option_id: string;
  modifier_option_name: string;
  price: number;
  quantity: number;
  unit_quantity: number;
  enable_credit_pool: boolean;
  modifier_entries: IModifierJson[];
  listing_id?: string | null;
}

/**
 * Modifier Entity
 */
class ModifierEntity {
  id = '';

  uuid: string = createUuid();

  name = '';

  optionID = '';

  price = 0;

  quantity = 1;

  unitQuantity = 1;

  enableCreditPool = false;

  modifierEntries: ModifierEntity[] = [];

  listingID: string | null = null;

  subtotal = 0;

  i18n: IObject = {};

  init = () => {
    this.id = '';
    this.optionID = '';
    this.uuid = createUuid();
    this.name = '';
    this.price = 0;
    this.quantity = 1;
    this.unitQuantity = 1;
    this.enableCreditPool = false;
    this.modifierEntries = [];
    this.listingID = null;
    this.i18n = {};
  }

  initForModifier = (modifier: IModifier, unitQuantity: number) => {
    if (!modifier) throw new Error('Modifier is required.');

    this.init();

    this.id = modifier.id;
    this.optionID = modifier.id;
    this.name = modifier.name || '';
    this.price = modifier.price || 0;
    this.enableCreditPool = modifier.enableCreditPool || false;
    this.listingID = modifier.listingID || null;
    this.unitQuantity = unitQuantity || 1;
    this.i18n = modifier.i18n;
  }

  initForOrder = (data: any, listingID: string) => {
    if (!data) throw new Error('LineItem is required.');

    this.init();

    let modifierEntries: any[] = [];
    if (data) {
      this.id = data.id || '';
      this.optionID = data.modifier_set_option_id || '';
      this.name = data.modifier_option_name || '';
      this.listingID = listingID || null;
      this.quantity = data.quantity || 0;
      this.unitQuantity = data.unit_quantity || 1;
      this.price = data.price || 0;
      this.enableCreditPool = data.enable_credit_pool || false;
      this.i18n = data.translation_info;

      if (Array.isArray(data.modifier_entries)) {
        modifierEntries = data.modifier_entries;
      }
    }

    modifierEntries.forEach(item => {
      const modifierEntity = new ModifierEntity();
      modifierEntity.initForOrder(item, listingID);
      this.modifierEntries.push(modifierEntity);
    });
  }

  initForLineItemJson = (data: IModifierJson, lineItemEntity: LineItemEntity) => {
    if (!data) throw new Error('ModifierJson is required.');

    this.init();

    let modifierEntries: any[] = [];
    if (data) {
      this.optionID = data.modifier_set_option_id || '';
      this.name = data.modifier_option_name || '';
      this.listingID = data.listing_id || lineItemEntity.id || null;
      this.quantity = data.quantity || 0;
      this.unitQuantity = data.unit_quantity || 1;
      this.price = data.price || 0;
      this.enableCreditPool = data.enable_credit_pool || false;

      if (Array.isArray(data.modifier_entries)) {
        modifierEntries = data.modifier_entries;
      }
    }

    modifierEntries.forEach(item => {
      const modifierEntity = new ModifierEntity();
      modifierEntity.initForLineItemJson(item, lineItemEntity);
      this.modifierEntries.push(modifierEntity);
    });
  }

  toJson = (): IModifierJson => {
    const modifierJsons: IModifierJson[] = [];
    this.modifierEntries.forEach(item => modifierJsons.push(item.toJson()));
    const order: IModifierJson = {
      'uuid': createUuid(),
      'modifier_set_option_id': this.optionID,
      'modifier_option_name': this.name,
      'price': this.price,
      'quantity': this.quantity,
      'unit_quantity': this.unitQuantity,
      'enable_credit_pool': this.enableCreditPool,
      'modifier_entries': modifierJsons,
      // 'listing_id': this.listingID,
    }

    return order;
  }

  repairQuantity = (lineItemrQuantity: number) => {
    this.quantity = lineItemrQuantity * this.unitQuantity;

    this.modifierEntries.forEach(
      item => item.repairQuantity(this.quantity)
    );
  }

  calculate = (context: Context) => {
    /** 计算总计和小计 */
    this.price = Number(this.price) || 0;
    this.quantity = Number(this.quantity) || 0;
    const decimal = context.lineItemLevelDecimalPlaces;
    let subtotal = 0;
    this.modifierEntries.forEach(item => {
      item.calculate(context);
      subtotal = plus(subtotal, item.subtotal, decimal);
    });

    subtotal = plus(subtotal, times(this.quantity, this.price, decimal), decimal);

    this.subtotal = subtotal;
  }
}

export default ModifierEntity;
