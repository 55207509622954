import React from 'react';
import BasePage, { IBasePageProps } from '@/pages/BasePage';
import { prefix } from '.';
import AntdIcon from 'antd-mobile/es/icon';
import Button from 'antd-mobile/es/button';
// import DiningStyle from '@/components/DiningStyle';
import { setScreenColor, getAppTheme, getIntl, getIntlByPath } from '@/components/App/App';
import { formatCurrency } from '@/components/Payment';
import ListingsPanel from '@/components/ListingsPanel';
import Icon, { IconType } from '@/components/Icon';
// import Layout from '@/components/Layout';
import { IOrderInfo, ISetShippingMethodParams } from '@/lib/order-builder/models/OrderEntity';
import ActionType from '@/actions/action-type';
import { parseRouteParams, getPlatformPayInfo, setPlatformPayInfo, IPayInfoParams, createRouteUrl, findNode } from '@/utils';
import { IStoreTable, IStore, IStoreConfig, IStorePickupLocation } from '@/actions/store-action';
import { parseShoppingCartData } from '@/utils/cart';
import paths from '@/routes/paths';
import LineItemEntity from '@/lib/order-builder/models/LineItemEntity';
import Constants from '@/constants';
// import { getCurrentShippingMethod, getDifferentShippingMethodStore, IParseOpeningHoursResult, parseOpeningHours, toWxMiniProgram } from '@/utils/app';
import { getDifferentShippingMethodStore, toWxMiniProgram, getI18nText, langFormat } from '@/utils/app';
// import ProcurementMethod from '@/components/ProcurementMethod';
import { IShoppingCartData, IStoreShoppingCartNote, IStoreShoppingCartDeliveryDate } from '@/actions/shopping-cart-action';
import Toast from 'antd-mobile/es/toast';
import Modal from 'antd-mobile/es/modal';
import { setCanAutoProcurementMethodPopup } from '../MenuListPage/MenuListPage';
import ShippingMethod from '@/lib/order-builder/enums/ShippingMethod';
import { plus } from '@/lib/order-builder/utils';
import { BigNumber } from 'bignumber.js';
import { goToRoute } from '@/utils/route-util';
import StoreInfo from '../express-order/ExpressOrder/StoreInfo';
import { IObject } from '@/components/bm-common';
import SideBar from '@/components/SideBar';
import dayjs from 'dayjs';
import InputItem from 'antd-mobile/es/input-item';
import { cloneDeep } from 'lodash';
import Skeleton from './Skeleton';
import Layout from '@/components/Layout';
// import ListingOutsideRules from '@/components/ListingOutsideRules';

export interface IShoppingCartPageProps extends IBasePageProps {
  forExpressOrder?: boolean;
  ExpressSkeleton?: React.ReactNode;
}

export interface IShoppingCartPageState {
  paying: boolean;
  storeSlug: string;
  shoppingCartData?: IShoppingCartData;
  loadedData: boolean;
  store?: IStore;
  storeConfig?: IStoreConfig;
  storeTables: IStoreTable[];
  showPopup: boolean;
  notes: IStoreShoppingCartNote;
  dates: IStoreShoppingCartDeliveryDate;
  showBottomBar: boolean; // 是否显示分享、删除
  isSplit: boolean; //是否拆单
  selectStore: IStore | undefined; //选择的store
  showSideBar: boolean; // 侧边栏
  initialTotal: number; // 合计
  lineItemCount: number; // 商品数量
  invalidLineItemCount: number; //无效的商品数量
  payDisabled: boolean; // 是否可以支付
  listingsPanelViews: React.ReactNode[]; // listinglineitem组件
  canChangeStore: boolean;
  storePickupLocations: IStorePickupLocation[] | undefined;
  searchName?: string;
  searchData: any;
}

class ShoppingCart extends BasePage<IShoppingCartPageProps, IShoppingCartPageState> {
  constructor(props: IShoppingCartPageProps) {
    super(props);
    this.state = {
      paying: false,
      storeSlug: '',
      loadedData: false,
      store: undefined,
      storeTables: [],
      showPopup: false,
      notes: {},
      dates: {},
      showBottomBar: false,
      isSplit: false,
      selectStore: undefined,
      showSideBar: false,
      initialTotal: 0,
      lineItemCount: 0,
      invalidLineItemCount: 0,
      payDisabled: true,
      listingsPanelViews: [],
      canChangeStore: false,
      storePickupLocations: [],
      searchData: {},
    }
  }

  componentDidMount() {
    this.openLoading();
    const appTheme = getAppTheme();
    if (appTheme.primaryColor && appTheme.darkBackgroundColor) {
      setScreenColor(appTheme.primaryColor, appTheme.darkBackgroundColor);
    }

    super.componentDidMount();
    this.autoCloseLoading = false;

    const routeParams = parseRouteParams(this.props);
    const { dispatch, reducersState, forExpressOrder } = this.props;
    const { platformConfig } = reducersState.app;
    this.setState({ storeSlug: routeParams.storeSlug || platformConfig.storeSlug });
    const { payInfo } = routeParams;
    const { selectStoreId, selectStoreSlug } = reducersState.supplierInfo;
    const { suppliers } = reducersState.app.platformAppSettings || {};
    let payStoreSlug = routeParams.storeSlug; // 下单时真正的storeSlug
    let isSplitOrder = false;

    if (payInfo && payInfo.startsWith(Constants.PLATFORMPAYINFO)) {
      const payInfoObj = getPlatformPayInfo(payInfo);
      payStoreSlug = payInfoObj.storeSlug;
      isSplitOrder = payInfoObj.isSplitOrder || false;
    }

    if (forExpressOrder) {
      if (routeParams.orderNumber) {
        dispatch({
          type: ActionType.GET_STORE_SHOPPING_CART_DATA,
          storeSlug: payStoreSlug,
          orderNumber: routeParams.orderNumber,
          isSplitOrder,
          resetDineInTable: true,
          callback: params => {
            console.info(params)
            dispatch({
              type: ActionType.GET_EXPRESS_ORDER_DATA,
              storeSlug: payStoreSlug,
              callback: (expressOrders: IShoppingCartData[]) => {
                this.closeLoading();
                this.setState({
                  loadedData: true,
                })
              }
            });
          },
        });
      } else {
        dispatch({
          type: ActionType.GET_EXPRESS_ORDER_DATA,
          storeSlug: payStoreSlug,
          callback: (expressOrders: IShoppingCartData[]) => {
            this.closeLoading();
            this.setState({
              loadedData: true,
            })
          }
        });
      }
    } else {
      dispatch({
        type: ActionType.GET_STORE_SHOPPING_CART_DATA,
        storeSlug: payStoreSlug,
        orderNumber: routeParams.orderNumber,
        isSplitOrder,
        resetDineInTable: false,
        callback: params => {
          this.closeLoading();
          const { shoppingCartData, store, storeConfig, storePickupLocations } = params;
          this.setState({ shoppingCartData, loadedData: true, store, storeConfig, storePickupLocations });
        },
      });
    }

    if (selectStoreId && suppliers) {
      dispatch({
        type: ActionType.QUERY_USER_ASSOCIATE_STORES,
        newRequest: false,
        callback: (stores: IStore[], associateTypesMap?: Map<string, IObject[]>) => {
          let store: IStore | undefined = undefined;
          stores.forEach(item => {
            if (item.slug === selectStoreSlug) {
              store = item;
            }
          });
          this.setState({ selectStore: store })
          if (stores.length > 1) {
            this.setState({
              canChangeStore: true,
            });
          }
        }
      })
    }
    if (routeParams.storeSlug && !suppliers) {
      dispatch({
        type: ActionType.QUERY_STORE_TABLES,
        storeSlug: routeParams.storeSlug,
        callback: storeTables => {
          this.setState({ storeTables });
        },
      });
    }
  }

  shouldComponentUpdate(nextProps: IShoppingCartPageProps) {

    const { reducersState } = nextProps;
    const { stopRender } = reducersState.cart;

    if (stopRender) return false;

    return true;
  }

  componentDidUpdate(prevProps: IShoppingCartPageProps, prevState: IShoppingCartPageState) {
    const {
      reducersState: prevReducersState,
    } = prevProps;
    const {
      reducersState,
    } = this.props;

    const {
      storeIDRefShoppingCartData: prevStoreIDRefShoppingCartData,
      storeIDRefExpressOrderData: prevStoreIDRefExpressOrderData,
    } = prevReducersState.cart;
    const {
      storeIDRefShoppingCartData,
      storeIDRefExpressOrderData,
    } = reducersState.cart;

    const {
      store: prevStore,
      searchName: preSearchName,
      searchData: preSearchData,
    } = prevState;
    const {
      store,
      searchName,
      searchData
    } = this.state;

    if (
      prevStoreIDRefShoppingCartData !== storeIDRefShoppingCartData
      || prevStoreIDRefExpressOrderData !== storeIDRefExpressOrderData
      || prevStore !== store
      || preSearchName !== searchName
      || preSearchData !== searchData
    ) {
      this.resetViews();
    }
  }

  // TODO store storeIDRefShoppingCartData storeIDRefExpressOrderData
  resetViews = () => {
    const { reducersState, forExpressOrder } = this.props;
    const { storeIDRefShoppingCartData, storeIDRefExpressOrderData } = reducersState.cart;
    const { platformConfig } = reducersState.app;

    const { store, loadedData } = this.state;
    const { id: storeID } = store || {};

    let payDisabled = false;
    let orderInfo: IOrderInfo | undefined = undefined;

    if (storeID && storeIDRefShoppingCartData[storeID]) {
      orderInfo = storeIDRefShoppingCartData[storeID].orderInfo
    }

    if (orderInfo) {
      const { shippingMethod, dineInParty } = orderInfo;
      if (shippingMethod === ShippingMethod.DINE_IN && (!dineInParty || !dineInParty.numberOfPeople)) {
        payDisabled = true;
      }
      
    }


    let lineItemCount = 0;
    let initialTotal = 0;
    let invalidLineItemCount = 0;
    const orderInfos: IOrderInfo[] = [];
    let showStoreInfo = false;
    let storeCount = 0;
    const data = forExpressOrder ? (this.state.searchName ? this.state.searchData : storeIDRefExpressOrderData) : storeIDRefShoppingCartData;
    Object.values(data).forEach((item: any) => {
      if (item.orderInfo.shippingMethod === 2) {
        if (String(storeID) === String(item.orderInfo.storeID)) {
          orderInfos.push(item.orderInfo);
          const parseResult = parseShoppingCartData(item);
          if (!this.state.searchName) {
            lineItemCount += parseResult.lineItemCount;
          }
          invalidLineItemCount += parseResult.invalidLineItemCount;
          initialTotal = plus(initialTotal, parseResult.initialTotal);
        }
      } else {
        orderInfos.push(item.orderInfo);
        const parseResult = parseShoppingCartData(item);
        if (!this.state.searchName) {
          lineItemCount += parseResult.lineItemCount;
        }
        invalidLineItemCount += parseResult.invalidLineItemCount;
        initialTotal = plus(initialTotal, parseResult.initialTotal);
      }

      if (item.orderData.line_items.length > 0) {
        storeCount = storeCount + 1;
      }
    });

    if (forExpressOrder && this.state.searchName) {
      Object.values(storeIDRefExpressOrderData).forEach((item: any) => {
        const parseResult = parseShoppingCartData(item);
        lineItemCount += parseResult.lineItemCount;
      })
    }
    if (lineItemCount < 1) {
      payDisabled = true;
    }

    if (orderInfos.length > 1) {
      showStoreInfo = true;
    }

    const listingsPanelViews: React.ReactNode[] = [];
    if (loadedData && orderInfos.length > 0) {
      orderInfos.forEach(orderInfo => {
        orderInfo.orderEntities.forEach(item => {
          if (item.lineItems.length > 0) {
            listingsPanelViews.push(
              <div key={item.storeID}>
                <ListingsPanel
                  key={item.storeID}
                  data={item}
                  showStoreTitle={true}
                  className="store-listings-panel"
                  showRightTopCount={false}
                  enableNotes={platformConfig.enableNotes}
                  onPlusClick={this.handlePlusClick}
                  onMinusClick={this.handleMinusClick}
                  reducersState={reducersState}
                  showStoreInfo={showStoreInfo}
                  orderInfo={orderInfo}
                  setNote={this.handleSetNotes}
                  setDate={this.handleSetDates}
                  showPickupLocation={true}
                  handleProcurementMethodChange={this.handleProcurementMethodChange}
                  hideChangeMethod={storeCount > 1 ? true : false}
                  onItemClick={this.handlePay}
                  showCheckoutBtn={storeCount > 1 ? true : false}
                  showChange={true}
                  showTotal={true}
                  forExpressOrder
                  handleChangeQuantityTo={this.handleChangeQuantityTo}
                  handlePromotionCode={this.handlePromotionCode}
                />
              </div>
            )
          }
        })
      })
    }

    this.setState({
      initialTotal,
      lineItemCount,
      invalidLineItemCount,
      // disableChangeMethod,
      payDisabled,
      listingsPanelViews,
    })
  }

  handleSelectStore = () => {
    console.info(dayjs);
    if (this.state.canChangeStore) {
      this.handleGotoRoute({
        path: paths.SELECT_STORES,
      });
    }
  }

  creatOrder = (storeSlug: string, isSplitOrder = false) => {
    const { dispatch, routeParams, reducersState, forExpressOrder } = this.props;
    const { userInfo } = reducersState.user;
    const { deviceInfo } = reducersState.app;
    if (deviceInfo.weChatMiniProgram) {
      const token = localStorage.getItem(Constants.ACCESS_TOKEN);
      if (token && userInfo && userInfo.id) {
        // 下单并支付
        dispatch({
          type: ActionType.LOADING,
          showLoading: true,
        });
        dispatch({
          type: ActionType.CREATE_ORDER,
          storeSlug,
          isSplitOrder,
          forExpressOrder: forExpressOrder ? true : false,
          callback: params => {
            if (params.success) {
              const { storeTitle = '', initialTotal = 0 } = (params.orderInfo as any) || {};
              toWxMiniProgram({
                miniProgramPath: `/pages/confirmPay/confirmPay?orderNumber=${params.orderNumber}`,
                data: {
                  token: params.token,
                  subtotal: params.subtotal,
                  storeID: params.storeID,
                  orderNumber: params.orderNumber,
                  storeSlug: params.storeSlug,
                  orderTime: params.orderTime,
                  orderInfo: {
                    storeTitle,
                    initialTotal
                  },
                  wxPageType: 'CONFIRM_PAY',
                },
              });
            } else {
              Toast.info(`Failed: ${params.message}`);
            }
            setTimeout(() => {
              dispatch({
                type: ActionType.LOADING,
                showLoading: false,
              })
            }, 300)
          }
        });
      } else {
        // 去微信授权
        toWxMiniProgram({
          miniProgramPath: '/pages/authorization/authorization',
          data: {
            storeSlug,
            wxPageType: 'AUTHORIZATION',
          },
        });
      }
    } else if (userInfo && userInfo.id && localStorage.getItem(Constants.IS_GUEST) !== 'true') {
      dispatch({
        type: ActionType.LOADING,
        showLoading: true,
        showLoadingBgc: true,
      });
      dispatch({
        type: ActionType.CREATE_ORDER,
        storeSlug,
        isSplitOrder,
        stopRender: true,
        forExpressOrder: forExpressOrder ? true : false,
        callback: params => {
          if (params.success) {
            if (forExpressOrder) {
              window.location.href = `${params.orderConfirmUrl}&fromPage=forExpressOrder`;
            } else if (routeParams.fromPage === 'eShop') {
              window.location.href = `${params.orderConfirmUrl}&fromPage=eShop`;
            } else {
              window.location.href = params.orderConfirmUrl;
            }
          } else {
            if (params.message && params.message.includes('listing_id')) {
              Modal.alert(getIntl().page.tips, getIntl().page.outOfStock, [
                {
                  text: getIntl().page.logInAgain,
                  onPress: () => { },
                }
              ]);
            } else {
              Modal.alert(getIntl().page.tips, params.message, [
                {
                  text: getIntl().page.logInAgain,
                  onPress: () => { },
                }
              ]);
            }
          }
          setTimeout(() => {
            dispatch({
              type: ActionType.LOADING,
              showLoading: false,
            })
          }, 300)
        }
      });
    } else {
      const payInfo: IPayInfoParams = {
        token: '',
        domain: reducersState.app.platformDomain,
        lang: reducersState.app.language,
        storeID: '',
        storeSlug,
        routeStoreSlug: routeParams.storeSlug, 
        isSplitOrder: isSplitOrder,
      }

      const payInfoString = setPlatformPayInfo(payInfo);
      if (routeParams.fromPage === 'eShop') {
        goToRoute({
          history: this.props.history,
          pathname: createRouteUrl(paths.CHECKOUT_BY_FROM, { storeSlug, fromPage: 'eShop', payInfo: payInfoString, }),
          clearPaths: false,
        });
      } else {
        goToRoute({
          history: this.props.history,
          pathname: createRouteUrl(paths.CHECKOUT, { storeSlug, payInfo: payInfoString, }),
          clearPaths: true,
        });
      }
    }
    if (!deviceInfo.weChatMiniProgram && localStorage.getItem(Constants.IS_GUEST) === 'true') {
      dispatch({
        type: ActionType.CLEAR_USER_INFO,
      })
    }
  }

  handlePay = (storeSlug: string, isSplitOrder?: boolean) => {
    const { dispatch } = this.props;
    const { storeConfig, showPopup, notes, dates } = this.state;
    if (storeConfig && storeConfig.purchaseRegulatedGoods && !showPopup) {
      this.setState({ showPopup: true, isSplit: isSplitOrder === true })
      return;
    }
    dispatch({
      type: ActionType.SET_STORE_SHOPPING_CART_INFO,
      notes,
      dates,
      callback: () => {
        this.creatOrder(storeSlug, isSplitOrder);
      }
    })
  }

  handlePlusClick = (data: LineItemEntity, quantity = 1) => {
    const { dispatch, forExpressOrder } = this.props;
    const { storeSlug, storeConfig } = this.state;
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;

    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'ADD',
      storeID: data.storeID,
      disableServiceCharge,
      quantity,
      forExpressOrder: forExpressOrder,
      callback: shoppingCartData => {
        this.setState({ shoppingCartData }, () => {
          if (this.state.searchName) {
            this.getDataByName(this.state.searchName, shoppingCartData);
          }
        });
      },
    });
  }

  handleMinusClick = (data: LineItemEntity, quantity = 1) => {
    const { dispatch, forExpressOrder } = this.props;
    const { storeSlug, storeConfig } = this.state;
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;

    dispatch({
      type: ActionType.MODIFY_LINE_ITEM_QUANTITY,
      storeSlug,
      uuid: data.uuid,
      operate: 'MINUS',
      storeID: data.storeID,
      disableServiceCharge,
      quantity,
      forExpressOrder,
      callback: shoppingCartData => {
        this.setState({ shoppingCartData });
        if (this.state.searchName) {
          this.getDataByName(this.state.searchName, shoppingCartData);
        }
      },
    });
  }

  handleChangeQuantityTo = (data: LineItemEntity, quantityTo: number) => {
    if (!data) return;

    // const { shoppingCartData } = this.state;
    // const { listingCountRef = {} } = shoppingCartData || {};
    let currentQuantity = 0;

    if (data.id && data.quantity > 0) {
      currentQuantity = data.quantity;
    }

    // add to
    if (quantityTo > currentQuantity) {
      this.handlePlusClick(data, quantityTo - currentQuantity);
    } else { // minus
      this.handleMinusClick(data, currentQuantity - quantityTo);
    }
  }

  clearShoppingCart = () => {
    const { dispatch, forExpressOrder } = this.props;
    Modal.alert(getIntl().page.tips, getIntl().page.clearShoppingCartTip, [
      {
        text: getIntl().page.signCancel,
        onPress: () => { },
      },
      {
        text: getIntl().page.logInAgain,
        onPress: () => {
          this.setState({
            shoppingCartData: undefined,
          })
          if (forExpressOrder) {
            dispatch({
              type: ActionType.SET_EXPRESS_ORDER_FOR_ZERO,
            });
            setTimeout(() => {
              if (this.state.searchName) {
                this.getDataByName(this.state.searchName);
              }
            }, 300)
          } else {
            dispatch({
              type: ActionType.CLEAR_SHOPPING_CART,
            });
          }
        },
      }
    ]);
  }

  handleProcurementMethodChange = (data: ISetShippingMethodParams, store?: IStore) => {
    const { dispatch, reducersState } = this.props;
    const { storeConfig } = this.state;
    const disableServiceCharge = storeConfig && storeConfig.disableServiceCharge ? true : false;

    const { storeIDRefShoppingCartData } = reducersState.cart;
    let storeIDs: string[] = [];
    let storeSlug = '';
    if (store) {
      storeIDs = getDifferentShippingMethodStore(store.id, storeIDRefShoppingCartData, data.shippingMethod);
      storeSlug = store.slug;
    }
    const { slugRefStorePickupLocations } = reducersState.store;
    const pickupLocations = slugRefStorePickupLocations[storeSlug] || [];
    if (storeIDs.length > 0) {
      Modal.alert('', getIntl().page.differentShippingMethodTips, [
        {
          text: getIntl().common.cancel,
          onPress: () => { },
        },
        {
          text: getIntl().common.continue,
          onPress: () => {
            dispatch({
              type: ActionType.CLEAR_STORES_SHOPPING_CART,
              storeIDs: storeIDs,
            });
            dispatch({
              type: ActionType.CHANGE_SHIPPING_METHOD,
              storeSlug,
              disableServiceCharge,
              data: data.shippingMethod === ShippingMethod.PICK_UP ? { ...data, pickupLocationID: (pickupLocations.length > 0 && pickupLocations[0].id) || undefined, pickupLocation: pickupLocations[0] || null } : data,
              callback: shoppingCartData => {
                this.setState({ shoppingCartData });
              }
            });
          },
        }
      ]);
    } else {
      dispatch({
        type: ActionType.CHANGE_SHIPPING_METHOD,
        storeSlug,
        disableServiceCharge,
        data: data.shippingMethod === ShippingMethod.PICK_UP ? { ...data, pickupLocationID: (pickupLocations.length > 0 && pickupLocations[0].id) || undefined, pickupLocation: pickupLocations[0] || null } : data,
        callback: shoppingCartData => {
          this.setState({ shoppingCartData });
        }
      });
    }
  }

  checkListingOutsideRules = () => {
    const { reducersState } = this.props;
    const { shoppingCartData } = this.state;
    let orderInfo: IOrderInfo | undefined = undefined;
    if (shoppingCartData) {
      orderInfo = shoppingCartData.orderInfo;
    }
    Modal.alert(
      'Message',
      <div className={`${prefix}-listing-outside-rules`}>
        {
          orderInfo && orderInfo.orderEntities.map(item => (
            <ListingsPanel
              key={item.id}
              data={item}
              showStoreTitle={orderInfo && orderInfo.superOrder}
              className="store-listings-panel"
              showRightTopCount={true}
              reducersState={reducersState}
            />
          ))
        }
        {
          orderInfo && orderInfo.orderEntities.map(item => (
            <ListingsPanel
              key={item.id}
              data={item}
              showStoreTitle={orderInfo && orderInfo.superOrder}
              className="store-listings-panel"
              showRightTopCount={true}
              reducersState={reducersState}
            />
          ))
        }
      </div>,
      [
        {
          text: getIntl().common.cancel,
          onPress: () => console.info('cancel'),
        }, {
          text: getIntl().common.yes,
          onPress: () => console.info('ok'),
        }
      ],
    );
  }

  handleSetNotes = (storeID: string, note: string) => {
    const { notes } = this.state;
    notes[storeID] = note;
    this.setState({ notes });
  }

  handleSetDates = (storeID: string, date: Date) => {
    const { dates } = this.state;
    dates[storeID] = date;
    this.setState({ dates });
  }

  handleShowSideBar = () => {
    this.setState({
      showSideBar: !this.state.showSideBar,
    });
  }

  onOpenChange = () => {
    const { reducersState } = this.props;
    const { platformConfig } = reducersState.app;
    if (platformConfig.showSideBar) {
      this.setState({
        showSideBar: !this.state.showSideBar,
      });
    }
  }

  handlePromotionCode = (storeID: string, discountScript: string[], promotionCode: string, promotionName: string, membershipLevelID: string, cancel = false) => {
    const { forExpressOrder, dispatch } = this.props;
    const { storeSlug } = this.state;

    dispatch({
      type: ActionType.SET_DISCOUNT_VALUE,
      storeSlug,
      storeID: storeID,
      discountScript,
      promotionName,
      promotionCode,
      membershipLevelID,
      cancel,
      forExpressOrder,
      callback: shoppingCartData => {
        this.setState({ shoppingCartData });
      },
    });
  }

  getDataByName = (key: string, shoppingCartData?: any) => {
    const { reducersState } = this.props;
    const { storeIDRefExpressOrderData } = reducersState.cart;
    const data = shoppingCartData ? cloneDeep(shoppingCartData) : cloneDeep(storeIDRefExpressOrderData);
    if (key) {
      if (shoppingCartData) {
        data.orderData['line_items'] = shoppingCartData.orderData.line_items.filter((_item: any) => _item.label.toLowerCase().indexOf(key.toLowerCase()) >= 0);
        data.orderInfo.orderEntities[0].lineItems = shoppingCartData.orderInfo.orderEntities[0].lineItems.filter((_item: any) => getI18nText(_item, 'name', reducersState.app.language) ? getI18nText(_item, 'name', reducersState.app.language).toLowerCase().indexOf(key.toLowerCase()) >= 0 :  _item.name.toLowerCase().indexOf(key.toLowerCase()) >= 0);
        const a = this.state.searchData;
        a[shoppingCartData.orderData.store_id] = data;
        this.setState({
          searchData: a,
        })
      } else {
        Object.values(data).forEach((item: any) => {
          item.orderData['line_items'] = item.orderData.line_items.filter((_item: any) => _item.label.toLowerCase().indexOf(key.toLowerCase()) >= 0);
          item.orderInfo.orderEntities[0].lineItems = item.orderInfo.orderEntities[0].lineItems.filter((_item: any) => getI18nText(_item, 'name', reducersState.app.language) ? getI18nText(_item, 'name', reducersState.app.language).toLowerCase().indexOf(key.toLowerCase()) >= 0 :  _item.name.toLowerCase().indexOf(key.toLowerCase()) >= 0);
        })
        if (data) {
          this.setState({
            searchData: data,
          })
        }
  
      }
      this.resetViews();
    }
  }

  handleCheckShoppingCart = () => {
    const { reducersState, routeParams } = this.props;
    const { storeIDRefShoppingCartData } = reducersState.cart;
    const { slugRefStore } = reducersState.store;
    let currentStoreHasItems = false;
    const storeIDList = [];
    let hasManyStores = false;
    Object.keys(storeIDRefShoppingCartData).forEach(key => {
      if (storeIDRefShoppingCartData[key].orderData.line_items.length > 0) {
        if (slugRefStore[routeParams.storeSlug].id === key) {
          currentStoreHasItems = true;
        }
        storeIDList.push(key);
      }
    })
    if (storeIDList.length >= 2) {
      hasManyStores = true;
    }
    return {
      currentStoreHasItems,
      hasManyStores,
    }
  }

  handleLeftBack = () => {
    const { routeParams, forExpressOrder, reducersState } = this.props;
    const { storeSlug } = this.state;
    setCanAutoProcurementMethodPopup(false);
    if (forExpressOrder) {
      this.handleGoBack({
        path: paths.HOME,
      })
    } else if (routeParams.fromPage === 'eShop') {
      this.handleGoBack({
        path: paths.ESHOP,
      })
    } else {
      if (localStorage.getItem('MENUS_SHORTCUT_CODE')) {
        this.handleGoBack({
          path: paths.MENU_LIST_QR_ORDER,
          pathParams: { shortcutCode: localStorage.getItem('MENUS_SHORTCUT_CODE') },
          runGoBack: true,
        })
      } else {
        const { slugRefTables } = reducersState.store;
        const { storeIDRefShoppingCartData } = reducersState.cart;
        const { store } = this.state;
        const { id: storeID } = store || {};
        let orderInfo: IOrderInfo | undefined = undefined;
        if (storeID && storeIDRefShoppingCartData[storeID]) {
          orderInfo = storeIDRefShoppingCartData[storeID].orderInfo
        }
        const tables = slugRefTables[storeSlug] || [];
        let queryStr = '';
        const mode = localStorage.getItem(Constants.DEDICATED_QUERY);
        if (mode === 'D') {
          queryStr = 'mode=dedicated';
        }
        const { dineInParty } = orderInfo || {};
        if (dineInParty && dineInParty.tableID) {
          const table: IStoreTable | undefined = findNode(tables, 'id', dineInParty.tableID);
          if (table) {
            queryStr += `${mode === 'D' ? `&uuid=${table.uuid}` : `uuid=${table.uuid}`}`;
          }
        }
        this.handleGoBack({
          path: paths.MENU_LIST,
          pathParams: { storeSlug },
          runGoBack: true,
          search: queryStr,
        })
      }
    }
  }

  handleLeftClick = () => {
    const { reducersState } = this.props;
    const { suppliers } = reducersState.app.platformAppSettings || {};
    if (!suppliers) {
      const { currentStoreHasItems } = this.handleCheckShoppingCart();
      if (currentStoreHasItems) {
        Modal.alert(getIntl().page.reminder, getIntl().page.leaveTheShoppingCartTip, [
          {
            text: getIntl().page.leave,
            onPress: () => {
              this.handleLeftBack();
            },
          },
          {
            text: getIntl().page.stay,
            onPress: () => {},
          }
        ]);
      } else {
        this.handleLeftBack();
      }
    } else {
      this.handleLeftBack();
    }

  }

  handlePayClick = () => {
    const { reducersState } = this.props;
    const { suppliers } = reducersState.app.platformAppSettings || {};
    const { storeSlug } = this.state;
    if (!suppliers) {
      const { hasManyStores } = this.handleCheckShoppingCart();
      if (hasManyStores) {
        Modal.alert(getIntl().page.reminder, getIntl().page.moreThanOneStoresTip, [
          {
            text: getIntl().page.editCart,
            onPress: () => {
            },
          },
          {
            text: getIntl().page.checkoutTitle,
            onPress: () => {
              this.handlePay(storeSlug);
            },
          }
        ]);
      } else {
        this.handlePay(storeSlug);
      }
    } else {
      this.handlePay(storeSlug);
    }
  }


  render() {
    const { reducersState, dispatch, forExpressOrder, ExpressSkeleton } = this.props;
    const {
      shoppingCartData,
      paying,
      loadedData,
      storeSlug,
      notes,
      dates,
      showPopup,
      selectStore,
      isSplit,
      initialTotal,
      lineItemCount,
      invalidLineItemCount,
      payDisabled,
      listingsPanelViews,
      storeConfig,
      canChangeStore,
    } = this.state;
    let orderInfo: IOrderInfo | undefined = undefined;
    if (shoppingCartData) {
      orderInfo = shoppingCartData.orderInfo;
    }

    const { suppliers } = reducersState.app.platformAppSettings || {};

    const layoutProps: IObject = {};
    const { platformConfig } = reducersState.app;
    const { showSideBar } = platformConfig;
    if (showSideBar && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' && suppliers) {
      layoutProps.navIcon = <Icon className="html-embed-7 side-bar-icon" type={IconType.MENUS_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.onOpenChange();
      }
    } else {
      layoutProps.navIcon = <Icon className="html-embed-7" type={IconType.LEFT_OUTLINED} />;
      layoutProps.onLeftClick = () => {
        this.handleLeftClick();
      }
    }

    return (
      <Layout
        {...this.props}
        className={`${prefix}`}
        navIcon={<Icon className="html-embed-7 w-embed" type={IconType.LEFT_OUTLINED} />}
        {...layoutProps}
        navContent={getIntlByPath(forExpressOrder ? 'page.expressOrderTitle' : 'page.shoppingCart')}
        navRightContent={<Icon className="circle-back-icon html-embed-7 w-embed" type={IconType.RECYCLE_BIN_OUTLINED} onClick={this.clearShoppingCart} />}
      >
        {
          loadedData &&  lineItemCount <= 0 && !forExpressOrder &&
          <div className={`no-content-btns ${localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' ? 'has-tab-bar' : ''}`}>
            <div className="start-ordering" onClick={layoutProps.onLeftClick}>
              {getIntl().page.startOrdering}
            </div>
          </div>
        }
        {
          !forExpressOrder ? false && <Skeleton  onClick={layoutProps.onLeftClick}/> : !loadedData &&  ExpressSkeleton
        }
        {
          loadedData && (forExpressOrder || lineItemCount > 0 || invalidLineItemCount > 0) &&
          <div className="bm-p-shopping-cart-margin">
            {
              !suppliers &&
              <div className="background-top"></div>
            }
            <div className="section no-padding">
              <div className="container top w-container">
                <div className="div-block-485">
                  <div className="left-block">{loadedData && listingsPanelViews}</div>
                  <div className="right-block">
                    <div className={`check-out ${suppliers? 'has-suppliers': ''}`}>
                      {
                        suppliers &&
                        <div className="search-wrap">
                          <InputItem
                            name="verify"
                            autoComplete="off"
                            onChange={value => {
                              this.setState({
                                searchName: value,
                              })
                              this.getDataByName(value);
                            }}
                            placeholder={getIntl().page.searchItems}
                            value={this.state.searchName}
                            className="search-item"
                          />
                          <div className="search-icon-wrap">
                            <Icon
                              type={IconType.SEARCH_OUTLINED}
                              className="search-icon"
                            />
                          </div>
                        </div>
                      }
                      {
                        suppliers && selectStore &&
                        <StoreInfo
                          store={selectStore}
                          reducersState={reducersState}
                          handleSelectStore={this.handleSelectStore}
                          canChangeStore={canChangeStore}
                        />
                      }
                      {
                        loadedData &&
                        <>
                          <div className={`confirm-pay div-block-510 ${forExpressOrder && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' ? 'has-tab-bar' : ''}`}>
                            <div className="text-block-90 less-spacing">
                              {
                                lineItemCount <= 0 ? `${getIntl().common.total}: ${new BigNumber(Math.abs(initialTotal)).toFormat(2)}` : `${getIntl().common.total}: ${formatCurrency(initialTotal, orderInfo && orderInfo.currencyCode)}`
                              }
                            </div>
                            <div className="text-block-91">
                              {langFormat(getIntlByPath(`page.${lineItemCount <= 1 ?'nowItemsCounts': 'itemsCount'}`), { count: lineItemCount })}
                            </div>
                            <Button className="pay-now-block w-inline-block"  type="primary" disabled={payDisabled} onClick={() => { this.handlePayClick() }}>
                              <div className="text-block-17"> {getIntl().page.checkoutTitle}</div>
                            </Button>
                          </div>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {
          loadedData && !forExpressOrder && lineItemCount <= 0 && invalidLineItemCount <= 0 &&
          <div className="no-content-panel">
            <Icon type={IconType.TAG_ITEM} />
            <div className="no-content-info">
              {getIntl().page.shoppingCartEmpty}
            </div>
          </div>
        }
        {
          paying &&
          <div className="co-checking-wrapper">
            <div className="co-checking">
              <div className="co-checking-loading">
                <AntdIcon type="loading" />
              </div>
              <div className="co-checking-text">
                {getIntl().page.paying}
              </div>
            </div>
          </div>
        }
        <Modal
          className="modal-body-left"
          transparent
          visible={showPopup}
          title={getIntl().page.reminder}
          footer={
            [
              {
                text: getIntl().common.cancel,
                onPress: () => {
                  this.setState({
                    showPopup: false,
                  })
                },
              },
              {
                text: getIntl().page.agree,
                onPress: () => {
                  this.setState({
                    showPopup: true,
                  });
                  dispatch({
                    type: ActionType.SET_STORE_SHOPPING_CART_INFO,
                    notes,
                    dates,
                    callback: () => {
                      this.creatOrder(storeSlug, isSplit);
                    }
                  })
                },
              }
            ]
          }
        >
          {getI18nText(storeConfig, 'purchase_regulated_tips', reducersState.app.language) || (storeConfig && storeConfig.purchaseRegulatedTips)}
        </Modal>
        {
          showSideBar &&
          <SideBar
            showSideBar={this.state.showSideBar}
            onItemClick={this.handleShowSideBar}
            reducersState={reducersState}
          />
        }
        {
          loadedData &&
          // <div className={`make-payment ${(localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' && loadedData &&  lineItemCount <= 0 && !forExpressOrder) ? 'has-tab-bar' : ''}`}>
          <div className={`make-payment ${forExpressOrder && localStorage.getItem(Constants.ADD_EXPRESS_ORDER) !== 'true' ? 'has-tab-bar' : ''}`}>
            <div className="bottom-nav cart-process">
              <div className="container bottom w-container">
                <div className="checkout-tablet">
                  <div>
                    <div className="text-block-90 less-spacing">
                      {
                        lineItemCount <= 0 ? `${getIntl().common.total}: ${new BigNumber(Math.abs(initialTotal)).toFormat(2)}` : `${getIntl().common.total}: ${formatCurrency(initialTotal, orderInfo && orderInfo.currencyCode)}`
                      }
                    </div>
                    <div className="text-block-91">
                      {langFormat(getIntlByPath(`page.${lineItemCount <= 1?'nowItemsCounts': 'itemsCount'}`), { count: lineItemCount })}
                    </div>
                  </div>
                  <Button className="pay-now-block"  type="primary" disabled={payDisabled} onClick={() => { this.handlePayClick() }}>
                    <div className="text-block-17"> {getIntl().page.checkoutTitle}</div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        }

      </Layout>
    );
  }
}

export default ShoppingCart;
