export default {
  common: {
    total: '合計',
    subtotal: '小計',
    paidTotal: '已付金額',
    invoiceState: '支付類型',
    fullPaid: '全部支付',
    discount: '折扣',
    serviceFee: '服務費',
    taxFee: '稅額',
    rounding: '除整',
    payment: '支付',
    today: '今天',
    tomorrow: '明天',
    now: '現在',
    save: '保存',
    tips: '提示',
    confirm: '確認',
    loading: '加載中...',
    payfail: '支付失敗, 請稍後再試',
    search: '搜索',
    password: '密碼',
    cancel: '取消',
    change: '更換',
    back: '返回',
    continue: '繼續',
    send: '發送',
    monday: '星期一',
    tuesday: '星期二',
    wednesday: '星期三',
    thursday: '星期四',
    friday: '星期五',
    saturday: '星期六',
    sunday: '星期日',
    unpaid: '未支付',
    paid: '已支付',
    preparing: '準備中',
    ready: '已準備好',
    completed: '已完成',
    next: '下一步',
    voided: '取消',
    no: '否',
    yes: '是',
    Mon: '一',
    Tue: '二',
    Wed: '三',
    Thu: '四',
    Fri: '五',
    Sat: '六',
    Sun: '日',
    Jan: '一月',
    Feb: '二月',
    Mar: '三月',
    Apr: '四月',
    May: '五月',
    Jun: '六月',
    Jul: '七月',
    Aug: '八月',
    Sep: '九月',
    Oct: '十月',
    Nov: '十一月',
    Dec: '十二月',
    submit: '提交',
    edit: '編輯',
    payErrorTips: '支付:交易金額不能低於0.2',
    activated: '激活',
    miniProgramTips: '“添加到我的小程序” 使用更快捷',
    searchHere: '搜索附近',
    any: '任意',
    type: '類型',
    paymentAmount: '支付金額',
    month: '月',
    day: '日',
    order: '訂單',
    deliverOn: '交付時間為',
    shallOrderBy: '{customer}{phone}通過 有鳥到 在{deliveryDate}下的訂單',
    birdymart: '有鳥到',
    customer: '顧客：',
    sendTo: '收件人：',
    subject: '主題：',
    subjectCon: '有鳥到 訂單 - 訂單編號: {referenceID}',
    subjectTo: '有鳥到 訂單',
    emailSent: '郵件已發送！',
    monthCapitals: '月',
    dayCapitals: '日',
    yearCapitals: '年',
    partialPaid: '部分支付',
    fulfilled: '完成',
    partiallyFulfilled: '部分完成',
    deferred: '延遲',
  },
  invoice: {
    invoice: '發票',
    enterprise: '企業',
    personal: '個人',
    invoiceName: '姓名',
    invoiceEmail: '郵箱',
    invoiceType: '發票類型',
    otherInfo: '其他信息',
    companyName: '企業名稱',
    taxID: '稅務編號',
    beneficiaryBank: '收款行',
    bankAccount :'銀行帳戶',
    enterpriseAddress: '企業地址',
    enterprisePhone: '企業電話',
    confirm: '確認',
    submitted1: '發票已提交',
    submitted2: '請稍候檢查郵箱/電話或掃描',
    submitted3: '發票二維碼再次下載發票',
    invoicing1: '正在開發票',
    invoicing2: '已成功提交發票信息',
    invoicing3: '正在開發票，請稍候。。。',
    failed1: '未能提交發票',
    failed2: '您的發票申請失敗，請重新開發票',
    invoiceQR1: '二維碼無效',
    invoiceQR2: '請聯繫商家：',
    invoiceWait1: '訂單正在同步',
    invoiceWait2: '發票正在同步，請',
    invoiceWait3: '稍後再開發票',
    payer: '付款人',
    payee: '收款方',
    invoiceAmount: '發票金額',
    issueTime: '開票時間',
    invoiceCode: '發票代碼',
    invoiceNumber: '發票號碼',
    downloadInvoice: '下載發票（PDF）',
    rebill: '重開發票',
    viewDetails: '詳情',
  },
  page: {
    backHome: '返回首頁',
    orderDetail: '訂單詳情',
    paymentSuccessful: '下單成功!',
    paymentFailed: '支付失败!',
    orderCompleted: '訂單已完成',
    payResultsTitle: '支付',
    selectPayTitle: '收銀台',
    processing: '處理中…',
    urlErrorTip: '參數异常',
    checkoutTitle: '下單',
    ordersTitle: '訂單',
    storesTitle: '店鋪',
    categoryTitle: '分類',
    menusTitle: '菜單',
    homeTitle: '首頁',
    recordsTitle: '記錄',
    promotionsTitle: '推廣',
    eshopTitle: '購物',
    membersTitle: '會員',
    paymentCardTitle: '支付卡',
    creditCardTitle: '信用卡',
    confirmAndPay: '確認並支付',
    takeAway: '外賣',
    delivery: '速遞',
    dineIn: '堂食',
    pickUp: '自取',
    pickUpTime: '自取時間',
    chooseLocation: '選擇地址',
    pickupAddress: '提貨地址',
    deliveryAddress: '速遞地址',
    book: '餐桌预定',
    browse: '全部',
    foodOrdering: '點餐',
    quickTag: '運費 {quick}',
    advanceOrderTips: '需要提前{day}天訂購',
    deliveryTag: '城市',
    percentageVoucherTag: 'Staff {number}% off',
    numberVoucherTag: 'Tasting Voucher {number}',
    immediately: '立即',
    addPayMethod: '添加支付方式',
    cvvOrPin: 'CVV / PIN',
    cvv: 'CVV',
    enterCvv: '輸入CVV',
    pin: 'PIN',
    expDate: '過期日',
    cardNumber: '卡號',
    payCardTypeError: '目前不支持這種類型的支付卡',
    payCardError: '請輸入正確的卡信息',
    itemsCount: '{count} 商品',
    balanceNode: '(餘額 {balance})',
    paying: '正在支付...',
    payment: '支付',
    selectPaymentMethod: '選擇支付方式',
    giftCardAlreadyBound: '此禮品卡僅供持卡人使用',
    confirmBindCreditCard: '我想將此信用卡用於我未來的訂單',
    referenceID: '查閱編號',
    placeholderCardNumber: '輸入卡號',
    shoppingCart: '購物車',
    ok: '確定',
    signInPhoneNumberPlacehodler: '請輸入電話號碼',
    areaCodeTitle: '地區代碼',
    signin: '登錄',
    history: '歷史',
    results: '結果',
    exploreByLocation: 'Explore by locations',
    location: '區域',
    allLocation: '所有區域',
    chooseCheckoutMethod: '選擇一種結帳方法',
    notSignInTips: '您似乎尚未登錄',
    signInPleaseTips: '要享受最佳體驗，請立即登錄',
    noThanks: '不，謝謝',
    guestCheckout: '訪客結帳',
    mobileSignIn: '手機號登錄',
    continueAsGuest: '以訪客身份繼續',
    suspend: '暫停',
    deactivate: '停用',
    soldOut: '售罄',
    option: '選項',
    choose: '選擇 {quanity} ',
    chooseUpTo: '選擇最多 {quanity} ',
    chooseAtLeast: '至少選擇 {quanity} ',
    chooseRange: '至少選擇 {quanityAtLeast} , 選擇最多 {quanityUpTo} ',
    itemInformation: '商品資訊',
    productName: '產品名稱',
    productPrice: '產品價格',
    productRemark: '產品備註（選填）',
    custom: '(自定義)',
    enter: '輸入',
    noAccount: '還沒有賬號?',
    signUpNow: '註冊',
    mobileSignin: '手機號登錄',
    emailSignin: '郵箱登錄',
    forgot: '忘記密碼',
    signInEmailPlacehodler: '請輸入郵箱',
    forgotPassword: '忘記密碼',
    mobileRetrieve: '手機號取回',
    emailRetrieve: '郵箱取回',
    emailRetrieveText: '我們將向您發送壹封電子郵件，說明如何恢復您的密碼',
    verifyCode: '驗證碼',
    favorite: '收藏',
    address: '地址',
    setting: '設置',
    clearCache: '清除緩存',
    changePassword: '修改密碼',
    language: '語言',
    terms: '使用條款',
    privacy: '隱私聲明',
    closed: '暫停營業',
    opening: '營業中',
    openHours: '營業時間',
    qrCodeTips: '提示',
    qrCodeTipsTitle: '商家資料尚未激活',
    qrCodeTipsCon: '請填寫應用程序啟動所需的資料。如有任何査詢，請通過onboarding_hk@bindo.com與Bindo聯系。',
    fbProgramApplication: '餐飲程式申請',
    paymentApplication: '聚合支付申請',
    featured: '特色店鋪',
    allStores: '全部店鋪',
    allMenus: '全部菜單',
    viewAll: '查看全部',
    orderTime: '落單時間',
    allOrders: '全部訂單',
    current: '當前',
    completed: '已完成',
    accountTitle: '帳戶',
    save: '保存',
    lineItemClosed: '非可售時間',
    gender: '性别',
    chooseGender: '選擇性別',
    male: '男',
    female: '女',
    other: '不透露',
    signup: '註冊',
    usernamePlaceholder: '請輸入用戶名',
    signUpmobile: '手機號註冊',
    signUpemail: '郵箱註冊',
    chooseLanguage: '選擇語言',
    noOrders: '沒有訂單歷史記錄。',
    noOrdersTip: '妳還沒有訂單記錄。',
    pleaseSignIn: '請登錄以開始。',
    reOrder: '再次下單',
    invoice: '發票',
    username: '用戶名',
    email: '電子郵件',
    phone: '手機號碼',
    birthday: '出生日期',
    paymentMethod: '支付方式',
    noPlatformDescribe: '頁面不存在或者路徑錯誤',
    iAgree: '我同意',
    splashDone: '開始',
    haveAccount: '還沒有賬號?',
    privacyPolicy: '《隱私政策》',
    termsandConditions: '《使用條款》',
    signOut: '登出',
    orderNumber: '訂單編號',
    takeAwayTime: '取货時間',
    shoppingCartEmpty: '空購物車',
    startOrdering: '開始訂購',
    favoriteListEmpty: '暫無收藏',
    errorPhoneTips: '請輸入正確的手機號',
    signInTips: '請先登錄',
    preparingTip: '您的訂單正在準備中。敬請期待。',
    readyTip: '您點的菜準備好了！',
    completedTip: '妳的食物怎麽樣？給我們壹個反饋！',
    paidTip: '系統已收到訂單，請耐心等待。',
    unpaidTip: '訂單將被取消。',
    emailReceipt: '電郵收據',
    userNoEmail: '請首先在“帳戶”頁面上驗證您的電子郵件地址。',
    guestTip: '若要繼續，請輸入電子郵件地址。',
    enterEmail: '輸入您的電子郵件地址',
    emailError: '郵件地址格式錯誤',
    sendEmail: '發送中...',
    feedback: '反饋',
    sentSuccess: '發送成功',
    emailAddress: '電子郵件收據已發送到您的電子郵件地址',
    feedBackTitle: '{storeTitle}怎麽樣?',
    feedBackTip: '我們會和餐廳分享妳的評分。',
    procurementMethod: '就餐方式',
    confirmTableNumber: '確認餐桌編號',
    enterTableNumber: '請輸入餐桌編號',
    tableNumberNotExist: '餐桌編號不存在',
    chooseTime: '選擇時間',
    confirmTableNumberTips: '掃二維碼或手動輸入',
    delete: '刪除',
    deleteTips: '刪除這張信用卡？',
    bookings: '預約',
    queuedSuccess: '已成功排隊',
    callingNumber: '當前主叫號碼',
    coverNo: '人數',
    enterPeople: '輸入人數',
    queueingTip: '如入座票號超過閣下所持的票號3個號碼或以上，請重新取票。',
    yourNumber: '您的隊列號',
    queueTimes: '排隊時間',
    seats: '座位',
    home: '首頁',
    expired: '失效',
    queueNumber: '隊列編號',
    booking: '訂座',
    queueing: '排隊',
    facebookSignin: 'Facebook登錄',
    adult: '成人',
    child: '兒童',
    chooseDate: '選擇日期',
    contactDetail: '聯系人詳細信息',
    note: '備註',
    notePlaceholder: '添加備註（額外的，不要胡椒粉，不要洋蔥等）',
    namePlaceholder: '輸入姓名',
    date: '日期',
    time: '時間',
    phoneNumber: '手機號碼',
    name: '姓名',
    bookingNo: '預約號',
    goBack: '返回',
    bookingID: '预约号:',
    bookingSuccess: '預約成功',
    bookingSuccessful: '您的預約申請已收到',
    bookingFailure: '預約失敗',
    booked: '等待確認',
    arrived: '到達',
    cancelled: '預定取消',
    confirmed: '已確認',
    noShow: '未到達',
    voicemail: '已留言',
    partiallyArrived: '就坐',
    late: '遲到',
    waitingForCon: '等待確認',
    failure: '失敗',
    bookingSuccessfulTips: '請稍候店員來電確認申請',
    sameCardTip: '請勿添加相同的卡片',
    signInErrorMsg: '請輸入正確賬號或密碼',
    menuFunc: '菜單',
    feedbackFunc: '反饋',
    newsFunc: '新聞',
    orderFunc: '點餐',
    aboutUsFunc: '店鋪資料',
    tableNumber: '桌號{tableNumber}',
    cancelBooking: '取消預約',
    backToHome: '返回首頁',
    createAt: '創建時間',
    bookingFull: '該時間段預約已滿，請更換預約時間',
    cancelBookingTip: '妳確定要取消這個預約嗎？',
    termsAndConditionPre: '完成此訂單即表示我同意',
    termsAndCondition: '《使用條款》',
    voidedTip: '期待您再次光臨。',
    wechatSignin: '微信登錄',
    appleSignin: '蘋果登錄',
    averageFeedback: '平均反饋',
    icons: '圖標',
    deviceInfo: '設備資訊',
    newsTitle: '最新消息',
    aboutUsTitle: '關於我們',
    noMoreContent: '沒有更多',
    createStore: '創建店鋪',
    storeInfo: '店鋪信息',
    stores: '邊度店鋪',
    fullName: '用戶名',
    storeName: '店鋪名稱',
    country: '國家或地區',
    storeAddress: '店鋪地址',
    zipCode: '郵政編碼',
    shopType: '店鋪類型',
    storeImages: '店鋪圖片',
    createTip: '快完成了！還剩{time}分鐘！',
    retail: '零售',
    dinner: '正餐',
    fastFood: '快餐',
    inactive: '未激活',
    noBookingTip: '沒有預約',
    noQueueingTip: '沒有排隊',
    feedbackTip: '暫無反饋',
    useNameInvalid: '無效的用戶名',
    phoneInvalid: '無效的手機號',
    emailInvalid: '無效的郵箱',
    storeAssociad: '平臺入駐',
    valid: '有效的',
    invalid: '註銷',
    approval: '批準',
    platformName: '平臺名稱',
    status: '狀態',
    environment: '環境',
    storeID: '店鋪ID',
    clearCacheSuccess: '清除緩存成功',
    createUserError: '此郵箱已被註冊',
    createStoreFailed: '创建店铺失败，请检查输入',
    bindo: '邊度',
    points: '積分',
    point: '積分',
    viewDetail: '查看詳情',
    upcomingBooking: '即將到來的預訂',
    newBooking: '新預訂',
    previousBooking: '過往預訂',
    noBooking: '渴望美食嗎？立即訂座',
    dateOfBirth: '生日: {date}',
    loginIn: '登入或注册',
    becomeMemberTip: '成為會員，享受專屬特權和獎勵',
    noQueue: '這個店鋪沒有開通排隊',
    noListings: '所選就餐管道沒有可選菜品',
    invalidLineItemTips: '庫存、餐式、時間等原因導致商品銷售失敗',
    myIDTitle: '我的ID',
    scanYourQRCode: '掃描您的二維碼以賺取更多積分',
    knowMore: '想更多地了解',
    dollars: 'Dollars?',
    clickHere: '點擊這裡',
    aptSuite: '詳細地址',
    storeConfigures: '店鋪配置',
    information: '信息',
    selectProcedureMethod: '選擇過程方法',
    platformID: '平臺ID',
    takeAwaySetup: '外賣設置',
    deliveryAdvanceOrderDays: '提前交貨訂單天數',
    PickUpInterval: '拾取間隔',
    deliverySetup: '速遞設置',
    deliveryMiles: '速遞裏程',
    deliveryFee: '速遞費',
    deliveryDesc: '發貨說明',
    dineInUnassigned: '堂食自取',
    deliveryMinAmount: '起送金額',
    addAddress: '添加地址',
    deleteAddressTips: '刪除這個地址?',
    placeholderApt: '輸入詳細地址',
    placeholderReceiver: '輸入收貨人',
    placeholderPhone: '輸入手機號碼',
    receiver: '收貨人',
    currentAmount: '當前積分',
    spend: '立即使用',
    myPrivilege: '我的禮遇',
    myCoupons: '我的優惠券',
    used: '已使用/過期',
    dollarsTitle: '代幣',
    brands: '品牌',
    seeAll: '查看所有',
    people: '人',
    latestUpdate: '最新更新時間: ',
    redemptionPeriod: '兌換優惠券日期至: ',
    couponExpiryDate: '優惠券使用日期至: ',
    useNow: '立即使用',
    couponNote: '優惠詳情',
    couponUsed: '已使用',
    couponExpired: '已過期',
    url: '地址',
    otherOrders: '其他訂單',
    share: '分享',
    route: '路線',
    applemap: '蘋果地圖',
    gaodemap: '高德地圖',
    googlemap: '谷歌地圖',
    noOpenBooking: '這個店鋪沒有開通預約',
    earnPoints: '獲得積分',
    redemption: '贖回',
    noPoint: '對不起！沒有數據',
    noDataTips: '對不起！沒有數據',
    usePoints: '兌換積分',
    aboutPoints: '關於積分',
    membershipGuide: '會員指南',
    payPoint: '使用積分',
    tggMembers: '{abbreviation}會員',
    myAccount: '我的帳戶',
    tggDollars: '{abbreviation}代幣',
    tableBooking: '訂座',
    dine: '用餐',
    shopping: '購物',
    aboutTGG: '關於{abbreviation}',
    availablePoints: '可用積分',
    rewardStatus: '獎勵規則',
    pointsEqDollar: '1積分 = 1 {dollar}',
    dollarEqPoints: '1 {dollar} = HK$1',
    minimumSpending: '最低支付金額為20 {dollar}',
    Reward: '兌換積分',
    enterPoint: '選擇妳想兌換積分的數量',
    pointDetail: '*任何外賣訂單都不需要服務費\n*積分按等級累加\n*TGG Dollar只適用於電子支付',
    map: '地圖',
    all: '全部',
    filters: '篩選',
    cuisine: '菜肴',
    distance: '距離',
    price: '價格',
    seatedTime: '入座時間',
    seatedTableNumber: '桌號',
    headcount: '人數',
    referenceNumber: '訂單編號',
    qrcodeShoppingResultTitle: '訂單',
    submitSuccess: '訂單已提交',
    newItems: '新增餐點',
    pendingItems: '待處理',
    submittedItems: '已提交',
    orderNow: '立即下單',
    addShoppingCart: '加入購物車',
    qrcodeInvalid: '二維碼已失效，如需就餐請聯系餐廳職員',
    recentAcitivities: '近期活動',
    nextTier: '升級金額',
    moneyPoint: '每花{money}=贏得{point}分',
    allBrands: '全部品牌',
    comingSoon: '敬請期待...',
    payBill: '支付',
    description: '簡介',
    myBookings: '我的預約',
    myQueueings: '我的排隊',
    myRecords: '我的記錄',
    myFavorites: '我的收藏',
    myAddress: '我的地址',
    myPayments: '我的支付',
    viewShoppingCart: '查看購物車',
    redeemSucceeded: '兌換成功',
    pointBalance: '積分餘額',
    storeCredit: '店鋪積分',
    pointTip: '妳已從餘額中兌換{integral}積分',
    redeemTip: '使用{abbreviation}積分探索獨家產品和電子商店的特權',
    birthdayTip: '與我們分享您的生日，享受生日驚喜！',
    supplierOrderfor: '選擇下單店鋪',
    buyerUserID: '您的用户編號是',
    buyerUserTips: '您暫時並沒有任何相關店鋪，請聯繫或等待',
    buyerUserContact: '團隊跟進！',
    register: '註冊',
    orderSynchronized: '訂單正在同步中',
    tryAgain: '請稍後再扫码落單',
    retry: '重試',
    selectDate: '選擇出生日期',
    accumulatedSpendings: '總消費金額={amount}',
    spendMoney: '消費',
    upgradedTo: '即可升級成為',
    highestLevelTips: '您現時已陞級到最高級別！',
    registeredAndSpent: '註冊 & 消費',
    spentYear: '1年內累積消費:',
    registerAsMember: '登記成為會員',
    loginUser: '登入用戶',
    scanner: '掃描二維碼',
    earnPointsFail: '二維碼無效或者已過期',
    earnPointsSuccess: '成功',
    membershipExpire: '將於{date}到期',
    district: '地區',
    members: '會員',
    everySpending: '每次消費',
    asap: '立即',
    canSchedule: '預定',
    notSchedule: '無法預定',
    selectPageextra: '有會員卡，但沒有帳戶?',
    placeClickHere: '請點擊這裡',
    receiveInformation: '是的！我想收到有關優惠、活動及最新消息之訊息',
    differentShippingMethodTips: '你已選擇了不同的就餐方式,若要繼續,你的購物車將會被清空',
    itemsTipsTitle: '您是否合乎法定年齡購買受管制物品',
    itemsTipsTitleContent: '受年齡限制的物品（包括酒精及煙草）不得向未滿18歲的人士出售，取餐時，你需出示有效身份證明文件',
    confirmOverAge: '同意，已滿18歲',
    currentLevelTitile: '您現在的等級',
    myProfile: '我的資料',
    depositeRequired: '餐廳資訊',
    dollarsBalance: 'Dollars 餘額',
    shareVia: '分享',
    copy: '複製',
    copySuccessMsg: '複製成功',
    shareSuccessMsg: '分享成功',
    recentOrders: '近期訂單',
    seeAllSuppliers: '查看所有供應商',
    searchByNames: '按名稱搜索...',
    showStoreTotal: '展示 {total} 結果',
    deliverySchedule: '交貨時間表',
    freeDelivery: '免費送貨',
    freeDeliveryAbove: '{amount}以上免費送貨',
    remove: '刪除',
    shippingCarNotePlaceholder: '請留下您的額外需求或備註信息',
    remark: '備註',
    enterVerifyCode: '請輸入有效的驗證碼',
    showQRTip: '收貨時顯示二維碼',
    resendCode: '重發驗證碼',
    expressOrderTitle: '快速訂單',
    searchItems: '搜索商品...',
    inventoryOptions: '庫存選項',
    addToExpressOrder: '添加到快速訂單',
    defaultRecorder: '默認再訂購金額',
    addItem: '+ 添加商品',
    invalidToken: '請再次登錄',
    signCancel: '取消',
    logInAgain: '確認',
    tips: '提示',
    registerDobMsg: '請提供真實的生日信息以獲取生日驚喜！',
    resendCodeTimer: '重發驗證碼 ({time}s)',
    reminder: '提示',
    agree: '同意',
    viewOrders: '查看訂單',
    newPassword: '請輸入新密碼',
    passwordLength: '為保證您的賬戶安全，請輸入六位數或以上的密碼',
    enterPayment: '輸入支付金額',
    checkOut: '結帳',
    paymentNotOpened: '該店鋪未開啟付款功能',
    mobileRepeat: '該手機號已被註冊，請嘗試登錄。如果您忘記密碼，可通過重置密碼找回賬戶。',
    sendCode: '發送驗證碼',
    storeNoBusinessTime: '店舖不在營業時間',
    noPointsAvailable: '暫無可用積分',
    expiryDate: '有效日期',
    alreadySoldOut: '已售罄，請選擇其他產品',
    paymentConfirmation: '確認支付',
    pointTipCustom: '你已從積分餘額中兌換{loyalty}{abbreviation} Dollar',
    changePasswordSuccessfully: '更新成功，請使用新密碼登入',
    passwordToShort: '為了您的帳戶安全，密碼不能短於6比特，請使用更長的密碼。',
    invalidAgain: '驗證碼或者手機無效，請重試',
    unknownStore: '店鋪資訊錯誤！',
    registered: '此電話號碼已被注册，如果您忘記密碼，您可以嘗試登入或重置密碼',
    invalidCode: '驗證碼無效，請檢查',
    encryptionPassword: '重置密碼失敗，請重試',
    loginAlreadyInUse: '登錄名不可用，請使用其它登錄名',
    invalidEmailFormat: '電子郵件：{failed}格式錯誤',
    createUserFailed: '創建用戶失敗',
    serviceUnavailable: '服務暫時不可用，請稍後再試',
    failedToCreateLogin: '在更新用戶登錄時創建用戶失敗並出現錯誤',
    saveCreateUserFailed: '創建用戶在保存手機時失敗並出現錯誤',
    saveCreateLinkedFailed: '帳號創建用戶失敗，創建客戶失敗',
    emailIsUser: '郵件：{failed}已被使用',
    createEmailFailed: '郵件創建帳號失敗，錯誤為：{failed}',
    updateVerifyCodeFailed: '更新驗證碼狀態失敗，錯誤為：{failed}',
    createPresenterFailed: '創建用戶失敗',
    loginFailed: '獲取用戶資訊失敗，請重新登入',
    currentPtsCustom: '當前{abbreviation}積分',
    pointsCustom: '{abbreviation}積分',
    payPointCustom: '使用{abbreviation}積分',
    earnPointsCustom: '獲得{abbreviation}積分',
    available: '可預訂',
    unavailable: '預定已滿',
    purchaseOrderTitle: '採購訂單記錄',
    viewPurchaseOrder: '訂單記錄',
    goodReceivedNotesTitle: '收貨單',
    noPurchasrOrderTip: '沒有采購單',
    purchaseOrder : '采購單',
    alert: '警告',
    purchaseOrderCancelTip: '是否確實要取消此采購訂單？',
    accountManager: '客戶經理',
    expectedDeliveryDate: '預計交貨日期',
    itemQuantity: '項目數量',
    serviceFee: '服務費',
    qty: '數量',
    code: '編碼',
    receive: '收貨',
    remarkPlaceholder: '添加備註（請準時發貨等）',
    supplierName: '供應商名稱',
    receivedOn: '接收時間',
    deliveryReceived: '交貨時間',
    receivedBy: '接收人',
    poCancelled: '已取消',
    received: '已接收',
    created: '已創建',
    accept: '已接受',
    orderQuantity: '訂貨量',
    goodReceivedNoteCancelTip: '您確定要取消此收貨單嗎？',
    goodReceivedNoteReceiveTip: '您確定要接收此收貨單貨物嗎？',
    purchaseOrderDetail: '采購單詳情',
    create: '創建',
    createReceive: '創建並接收',
    deliveredQuantity: '交付數量',
    fulfillAll: '完成所有',
    newGoodsReceivedNote: '新建收貨單',
    dDollars: 'Dollars',
    outOfStock: '此商品缺貨',
    enterPromotionCode: '請輸入有效的折扣碼',
    readAgree: '我已閱讀',
    nowItemsCounts: '{count} 商品',
    clearShoppingCartTip: '請確認是否要删除購物車中的所有產品',
    leaveTheShoppingCartTip: '您在此店鋪的訂單尚未完成，確定要離開此頁面嗎？\n *您的物品將保存在購物車中。',
    moreThanOneStoresTip: '您添加了超過1家店鋪的商品，您確定要結帳嗎',
    stay: '停留',
    leave: '離開',
    editCart: '編輯',
    usePointTip: '使用積分兌換 {name} Dollars 後, 將無法退款',
    usePointRedeem: '兑换',
    showRedeemable: '可兌換',
    redeemWidth: '換購：',
    redeemNow: '立即兌換',
    confirmRedeemTips: '{name}一經使用，不可退還。',
    return: '退貨',
    newReturnNote: '新建退貨單',
    returnNotesTitle: '退貨單',
    returned: '已退貨',
    returnNoteCancelTip: '您確定要取消此退貨單嗎？',
    returnNoteReceiveTip: '您確定要退回此退貨單貨物嗎？',
    receiveTip: '您無法「接收」此訂單，因為它尚未「接受」。',
    deferredInvoices: '延期訂單',
    thisWeek: '本周',
    thisMonth: '本月',
    monthAgo: '一個月前',
    invoices: '訂單',
    lastInvoiceDate: '最後下單日期',
    payAll: '全部付清',
    viewDetails: '查看詳情',
    othersProducts: '其他產品',
    payNow: '現在付款',
    backDataTips: '請檢查採購內容，確認後將不能修改',
  },
  pay: {
    oePay: '八達通',
    weChatPay: '微信支付',
    applePay: 'Apple Pay',
    googlePay: 'Google Pay',
    alipay: '支付寶',
    alipayHK: '支付寶香港',
    tapGoPay: '拍住賞',
    unionPay: '雲閃付',
    cardEnding: '卡尾號',
    giftCard: '禮品卡',
    creditCard: '信用卡',
    deferredBilling: '賬期付款',
    cashOnDelivery: '貨到付款',
  },
}
