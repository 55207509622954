export default {
  total: 'Total',
  subtotal: 'Subtotal',
  paidTotal: 'Paid Total',
  fullPaid: 'Full Payment',
  invoiceState: 'Invoice State',
  discount: 'Discount',
  serviceFee: 'Service Charge',
  taxFee: 'Tax',
  rounding: 'Rounding',
  payment: 'Payment',
  today: 'Today',
  tomorrow: 'Tomorrow',
  now: 'Now',
  save: 'Save',
  tips: 'Tips',
  confirm: 'Confirm',
  loading: 'Loading...',
  payfail: 'Payment failed. Please try again',
  search: 'Search',
  password: 'Password',
  cancel: 'Cancel',
  change: 'Change',
  back: 'Back',
  continue: 'Continue',
  send: 'Send',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  unpaid: 'Unpaid',
  paid: 'Paid',
  preparing: 'Preparing',
  ready: 'Ready',
  completed: 'Completed',
  voided: 'Canceled',
  next: 'Next',
  no: 'No',
  yes: 'Yes',
  Mon: 'Mon',
  Tue: 'Tue',
  Wed: 'Wed',
  Thu: 'Thu',
  Fri: 'Fri',
  Sat: 'Sat',
  Sun: 'Sun',
  Jan: 'Jan',
  Feb: 'Feb',
  Mar: 'Mar',
  Apr: 'Apr',
  May: 'May',
  Jun: 'Jun',
  Jul: 'Jul',
  Aug: 'Aug',
  Sep: 'Sep',
  Oct: 'Oct',
  Nov: 'Nov',
  Dec: 'Dec',
  submit: 'Submit',
  edit: 'Edit',
  payErrorTips: 'Payment:The amount cannot be less than 0.2',
  activated: 'Activated',
  miniProgramTips: '"Add to my mini-program" more convenient to use',
  searchHere: 'Search Here',
  any: 'Any',
  type: 'Type',
  paymentAmount: 'Payment Amount',
  month: 'month',
  day: 'day',
  order: 'Order',
  deliverOn: 'Deliver on',
  shallOrderBy: 'Order placed by {customer}{phone} at {deliveryDate} via Birdymart',
  birdymart: 'Birdymart',
  customer: 'Customer:',
  sendTo: 'To:',
  subject: 'Subject:',
  subjectCon: 'Birdymart order - Reference ID: {referenceID}',
  subjectTo: 'Birdymart order',
  emailSent: 'Email Sent!',
  monthCapitals: 'Month',
  dayCapitals: 'Day',
  yearCapitals: 'Year',
  fulfilled: 'Fulfilled',
  partiallyFulfilled: 'Partially Fulfilled',
  deferred: 'Deferred',
  partialPaid: 'Partial Payment',
  failure: '', // TODO
}
